export const timestampToDate = (timestamp) => {
    var date_ob = new Date(+timestamp);
    // year as 4 digits (YYYY)
    var year = date_ob.getFullYear();

    // month as 2 digits (MM)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    // hours as 2 digits (hh)
    var hours = ("0" + date_ob.getHours()).slice(-2);

    // minutes as 2 digits (mm)
    var minutes = ("0" + date_ob.getMinutes()).slice(-2);

    // seconds as 2 digits (ss)
    var seconds = ("0" + date_ob.getSeconds()).slice(-2);

    // date & time as YYYY-MM-DD hh:mm:ss format:
    return (
        year + "/" + month + "/" + date + " " + hours + ":" + minutes + ":" + seconds
    );
};

export const getYearDate = (timestamp) => {
    var date_ob = new Date(+timestamp);
    // year as 4 digits (YYYY)
    var year = date_ob.getFullYear();

    // month as 2 digits (MM)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    // date & time as YYYY-MM-DD hh:mm:ss format:
    return `${year}-${month}-${date}`
};

export const getDate = (timestamp) => {
    var date_ob = new Date(+timestamp);
    // year as 4 digits (YYYY)
    // month as 2 digits (MM)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    var day = isNaN(date_ob.getDay())
        ? null
        : ["日", "一", "二", "三", "四", "五", "六"][date_ob.getDay()];
    return `${month}/${date}(${day})`;
};

export const getDate2 = (timestamp) => {
    var date_ob = new Date(+timestamp);
    // year as 4 digits (YYYY)
    // month as 2 digits (MM)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    return `${month}/${date} `;
};

export const getTime = (timestamp) => {
    var date_ob = new Date(+timestamp);
    // hours as 2 digits (hh)
    var hours = ("0" + date_ob.getHours()).slice(-2);
    // minutes as 2 digits (mm)
    var minutes = ("0" + date_ob.getMinutes()).slice(-2);
    return hours + ":" + minutes;
};


export const calcRemainTime = (expTime) => {
    const currentTime = new Date().getTime();
    const adjExpTime = new Date(expTime).getTime();
    return adjExpTime - currentTime;
};
