import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    groups: null,
    groupOptions: [],
    latestRelation: null,
}

const hourSlice = createSlice({
    name: 'hour',
    initialState: initialState,
    reducers: {
        updateGroups(state, action) {
            let updatedGroups = {};
            action.payload.groups.forEach(hourGroup => {
                updatedGroups[hourGroup.hour_group_id] = hourGroup
            });
            state.groups = updatedGroups;
            state.groupOptions = action.payload.groups.map(hrGroup => ({
                value: hrGroup.hour_group_id,
                displayValue: hrGroup.name
            }));
        },
        updateRelation(state, action) {
            state.latestRelation = action.payload;
        }
    },
});

export const hourActions = hourSlice.actions;

export default hourSlice.reducer;