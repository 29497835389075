import React from 'react';
import NavigationItem from './NavigationItem';

const NavigationItems = () => {
    return (
        <nav className="navItems">
            <NavigationItem link='/activities' >最新認證活動</NavigationItem>
            <NavigationItem link='/news' >最新消息</NavigationItem>
            <NavigationItem link='/issues' >服務學習期刊</NavigationItem>
            <NavigationItem link='/rules' >相關辦法</NavigationItem>
            <NavigationItem link='/downloads' >表單下載</NavigationItem>
            <NavigationItem link='/QA' >常見問題</NavigationItem>
            <NavigationItem link='/volunteer' >服學原創</NavigationItem>
            <NavigationItem link='/about' >關於我們</NavigationItem>
        </nav>
    );
}

export default NavigationItems;
