import axios from './instance/axios';

const FileService =  {
    postFile: async (data) => {
        try{
            const response = await axios.post("/admin/editor/upload_file", data, {headers: {'Content-Type': 'multipart/form-data'}});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default FileService;