import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const News = lazy(() => import("./News/News"));
const QA = lazy(() => import("./QA/QA"));
const Meetings = lazy(() => import("./Meetings/Meetings"));
const Rules = lazy(() => import("./Rules/Rules"));
const Downloads = lazy(() => import("./Downloads/Downloads"));
const Banner = lazy(() => import("./Banner"));
const BannerEdit = lazy(() => import("./Banner/Edit"));
const Volunteer = lazy(() => import("./Articles/Volunteer/Volunteer"));
const About = lazy(() => import("./Articles/About/About"));
const Issues = lazy(() => import("./Issues/Issues"));

const Content = (props) => {
    const isAdmin = props.location.pathname.split('/')[1]==='admin';
    let { path } = useRouteMatch();
	const classes = useStyles();
    return (
		<Suspense fallback={<div className={classes.Suspense}></div>}>
            <Switch>
                {isAdmin && (<>
                    <Route exact path={`${path}/news`} component={News} />
                    <Route exact path={`${path}/QA`} component={QA} />
                    <Route exact path={`${path}/meetings`} component={Meetings} />
                    <Route exact path={`${path}/rules`} component={Rules} />
                    <Route exact path={`${path}/issues`} component={Issues} />
                    <Route exact path={`${path}/downloads`} component={Downloads} />
                    <Route exact path={`${path}/banner`} component={Banner} />
                    <Route path={`${path}/banner/edit/:id`} component={BannerEdit} />
                    <Route exact path={`${path}/articles/about`} component={About} />
                    <Route exact path={`${path}/articles/volunteer`} component={Volunteer} />
                </>)}
            </Switch>
        </Suspense>
    );
}

export default Content;

const useStyles = makeStyles((theme) => ({
    Suspense: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.default
    }
}));
