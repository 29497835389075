import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    Status: {
        color: props => `${props.color}`,
        display: 'flex',
        alignItems: 'center',
        fontSize: "inherit",
        '& .text': {
            margin: '0px 0 0 2px',
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: 700,
            textOverflow: "ellipsis"
        },
        '& .MuiSvgIcon-root': {
            fontSize: "inherit"
        }
    }
}));

const Status = (props) => {
    const classes = useStyles(props);

    return(
        <div className={`${classes.Status} status`}>
            {props.icon}
            <div className="text">
                {props.text}
            </div>
        </div>
    );
};

export default Status;