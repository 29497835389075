import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';

const NavigationItem = (props) => {
    const classes = useStyles();
    return (
        <NavLink to={props.link} className={classes.root}>
            <span>{props.children}</span>
        </NavLink>
    );
};

export default NavigationItem;

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
        padding: '0 0.5vw',
        textAlign: 'center',
        fontWeight: '600',
        transition: "0.2s",
        '&:after': {
            content: '""',
            display: 'block',
            width: '0%',
            height: '2px',
            backgroundColor: '#ff7326',
            transition: '0.2s'
        },
        '&:hover, &.active' :{
            color: theme.palette.text.primary,
            '&:after': {
                width: '100%',
            }
        }
    }
}));