import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const Logo = props => {
    const classes = useStyles(props);
    return (
        <Link to="/" className={`${classes.Logo} ${props.className}`}>
            <svg className="logomark" xmlns="http://www.w3.org/2000/svg" width="200" height="150.543" viewBox="0 0 200 150.543">
                <defs>
                <linearGradient id="gradient" x1="20%" y1="10%" x2="60%" y2="60%">
                    <stop offset="0%" style={{stopColor:"#ff194d", stopOpacity:1}} />
                    <stop offset="100%" style={{stopColor:"#ff7326", stopOpacity:1}}/>
                </linearGradient>
                </defs>
                <g id="layer1" transform="translate(-12.018 -94.639)">
                <path fill="url(#gradient)" id="path847-3-1-1" d="M136.539,0A14.139,14.139,0,1,0,150.68,14.14,14.139,14.139,0,0,0,136.539,0ZM200,23.8s-26.97,12.83-59.676,8.332c-37.2-4.135-49.834,30.666-60.168,47.4,18.765-24.466,41.1-5.845,31.6,12.133-11.294,20.737-45.01,44.992-47.926,47.066,3.322-2.122,41.981-26.593,53.27-22.672,0,0,6.63-.116,12.371,30.223C129.473,146.281,137.479,56.505,200,23.8ZM35.043,32.926a14.141,14.141,0,1,0,14.141,14.14,14.14,14.14,0,0,0-14.141-14.14ZM0,58.035v0S12.268,73.031,15.164,84.785s8.856,65.758,8.856,65.758,7.327-28.109,14.652-30.664c0,0,7.325-1.363,24.531,19.25,0,0,.057-.039.059-.039-.21-.4-32.265-61.452-4.149-69.336,0,0,14.44-4.678,20.949,9.894-.029-.144-3.083-15.1-17.883-16.672C46.929,61.355,20.1,69.449,0,58.035Z" transform="translate(12.018 94.639)" fillRule="evenodd"/>
                </g>
            </svg>
            <div className="logotype">
                <div className="topText">中央大學</div>
                <div className="bottomText">服務學習發展中心</div>
            </div>
        </Link>
    );
}

export default Logo;

const useStyles = makeStyles(theme => ({
    Logo:{
        display: "flex",
        alignItems: "center",
        transition: "0.2s",
        color: theme.palette.text.primary,
        "&:active, &:focus, &:hover": {
            color: theme.palette.text.primary
        },
        "& .logomark": {
            width: props => `${props.scale*12}vw`,
            height: props => `${props.scale*10}vw`,
            paddingRight: "5px"
        },
        "& .topText": {
            fontSize: props => `${props.scale*3.5}vw`,
            color: "inherit",
            lineHeight: 1,
            marginBottom: 2
        },
        "& .bottomText": {
            fontSize: props => `${props.scale*4.5}vw`,
            color: "inherit",
            fontWeight: 700,
            lineHeight: 1
        }
    },
    [theme.breakpoints.up('sm')]: {
        Logo:{
            "& .logomark": {
                width: props => `${props.scale*50}px`,
                height: props => `${props.scale*45}px`,
            },
            "& .topText": {
                fontSize: props => `${props.scale*16}px`
            },
            "& .bottomText": {
                fontSize: props => `${props.scale*20}px`
            }
        }
    }
}));