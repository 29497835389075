import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import Activities from './Activities';
const Settings = lazy(() => import("./Settings"));
const Awards = lazy(() => import("./Awards/Awards"));
const Questionnaire = lazy(() => import("./Questionnaire/Questionnaire"));
const Warnings = lazy(() => import("./Warnings/Warnings"));

const Passport = (props) => {
    const isAdmin = props.location.pathname.split('/')[1] === 'admin';
    let { path, url } = useRouteMatch();
    const classes = useStyles();
    return (
        <Suspense fallback={<div className={classes.Suspense}></div>}>
            <Switch>
                {isAdmin ? (<>
                    <Route path={path} exact>
                        <Redirect to={`${url}/activity`} />
                    </Route>
                    <Route path={`${path}/activity`} component={Activities} />
                    <Route path={`${path}/awards`} component={Awards} />
                    <Route path={`${path}/questionnaire`} component={Questionnaire} />
                    <Route path={`${path}/warnings`} component={Warnings} />
                    <Route path={`${path}/settings`} component={Settings} />
                </>) : (<>
                    <Route path={path} exact>
                        <Redirect to={`${url}/activity`} />
                    </Route>
                    <Route path={`${path}/warnings`} component={Warnings} />
                    <Route path={`${path}/activity`} component={Activities} />
                </>)}
            </Switch>
        </Suspense>
    );
}

export default Passport;

const useStyles = makeStyles((theme) => ({
    Suspense: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.default
    }
}));
