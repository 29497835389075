import React from "react";
import clsx from 'clsx';
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import Passport from "./Passport";
import Content from "./Content";
import Scholarship from "./Scholarship";
import User from "./User";
import BackStageMenu from "./Menu";

const drawerWidth = 260;

const BackStage = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	let { path, url } = useRouteMatch();
	const [open, setOpen] = React.useState(true);

	const handleDrawerOpen = () => {
		let tableRoot = document.getElementById('tableRoot');
		if (tableRoot)
			tableRoot.classList.add('backStageDrawerOpened');
		setOpen(true);
	};

	const handleDrawerClose = () => {
		let tableRoot = document.getElementById('tableRoot');
		if (tableRoot)
			tableRoot.classList.remove('backStageDrawerOpened');
		setOpen(false);
	};

	return (
		<div className={classes.BackStage}>
			<BackStageMenu opened={open} handleDrawerClose={handleDrawerClose} />
			<IconButton
				size="small"
				className={classes.menuButton}
				onClick={open ? handleDrawerClose : handleDrawerOpen}
				disableRipple
				edge="end"
			>
				<ArrowForwardIosIcon style={{color: theme.palette.text.primary}}/>
			</IconButton>
			<main className={clsx(classes.content, { [classes.contentShift]: open })}>
                <Switch>
                    <Route path={path} exact>
                        <Redirect to={`${url}/passport`} />
                    </Route>
                    <Route path={`${path}/passport`} component={Passport} />
                    <Route path={`${path}/content`} component={Content} />
                    <Route path={`${path}/scholarship`} component={Scholarship} />
                    <Route path={`${path}/users`} component={User} />
                </Switch>
			</main>
		</div>
	);
};

export default BackStage;

const useStyles = makeStyles((theme) => ({
	BackStage: {
		display: "flex",
		"& .content": {
			backgroundColor: theme.palette.background.default,
			width: "100%",
			padding: "20px 40px",
			minHeight: "calc(100vh - 40px)",
			color: theme.palette.text.primary,
			overflowX: "hidden",
		},
		'& h2, & h3, & h4': {
			color: theme.palette.text.primary,
		}
	},
	root: {
		display: 'flex',
	},
	menuButton: {
		color: 'white',
		zIndex: 100,
		background: theme.palette.background.paper,
		borderRadius: 5,
		position: 'fixed',
		boxShadow: "0 0px 10px rgba(0, 0, 0, 0.2)",
		left: 0,
		top: 80,
		"&:hover": {
			background: theme.palette.background.paper
		}
	},
	hide: {
		display: 'none',
	},
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		minHeight: "100vh",
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));
