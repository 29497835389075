import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux';

import App from './App';
import store from './store/index';
import { initGA, sendWebVitals } from './util/reactGA';

ReactDOM.render(
    <React.StrictMode>
        <DndProvider backend={HTML5Backend}>
        <Provider store={store} >
            <BrowserRouter>
            <App />
            </BrowserRouter>
        </Provider>
        </DndProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
initGA();
reportWebVitals(sendWebVitals);
