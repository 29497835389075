import axios from './instance/axios';

const UserService =  {
    authorization: (redirect_path) => {
        const qParams = [
            `response_type=code`,
            `redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_URI}`,
            `scope=${process.env.REACT_APP_OAUTH_SCOPE}`,
            `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
            `state=${redirect_path ?? '/'}`
        ].join("&");
        window.location.assign(`${process.env.REACT_APP_OAUTH_URL}?${qParams}`)
    },
    login: async (code) => {
        try{
            const response = await axios.get(`/oauth/?code=${code}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getInfo: async () => {
        try{
            const response = await axios.get('/user/user');
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStudentId: async (studentId) => {
        try{
            const response = await axios.get(`/admin/id/${studentId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getUserById: async (id) => {
        try{
            const response = await axios.get(`/admin/user/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getUserByName: async (name) => {
        try{
            const response = await axios.get(`/admin/user/name/${name}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getUserOrg: async (orgId, studentId) => {
        try{
            const response = await axios.get(`/organization/user/${orgId}/${studentId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default UserService;
