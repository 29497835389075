import React, { useState, useEffect } from 'react';
import { Link, withRouter, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const drawerWidth = 260;
const PersonalMenu = props => {
    const theme = useTheme();
    const classes = useStyles(theme);
    let { url, params } = useRouteMatch();
    const location = useLocation();
    const isAdmin = location.pathname.split('/')[1] === 'admin';
    const userPermission = useSelector(state => state.auth.data.permission ?? []);
    const userOffices = useSelector(state =>
        state.auth.data.organization.filter(org =>
            org["kindno"] !== null ||
            (org["children"] && org["children"].length > 0)
        ) ?? []
    );
    const [currentPage, setCurrentPage] = useState(null);
    const [subMenuOpen, setSubMenuOpen] = useState({});
    const content = isAdmin ? [
        {
            title: "學生學習護照",
            id: "passport",
            items: [
                {
                    title: "活動管理",
                    id: "activity",
                    subList: [
                        { title: "組織活動申請審核", id: "application" },
                        { title: "組織活動時數核發", id: "hour" },
                        { title: "組織活動查詢管理", id: "management" },
                        { title: "個人申請時數核發", id: "individual" },
                        { title: "創立活動", id: "apply" },
                    ]
                }, {
                    title: "金銀質獎",
                    id: "awards",
                    subList: [
                        { title: "通知設定", id: "settings" },
                        { title: "名單", id: "namelist" }
                    ]
                }, {
                    title: "預警系統",
                    id: "warnings",
                    subList: [
                        { title: "通知設定", id: "settings" },
                        { title: "名單", id: "namelist" }
                    ]
                }, {
                    title: "問卷設定",
                    id: "questionnaire"
                }, {
                    title: "時數體制",
                    id: "settings"
                }
            ]
        }, {
            title: "獎學金",
            id: "scholarship",
            items: [
                {
                    title: "獎學金審核",
                    id: "scholarship"
                }
            ]
        }, {
            title: "網頁內容管理",
            id: "content",
            items: [
                {
                    title: "最新消息",
                    id: "news"
                }, {
                    title: "常見問題",
                    id: "QA"
                }, {
                    title: "文章管理",
                    id: "articles",
                    subList: [
                        { title: "服學原創", id: "volunteer" },
                        { title: "關於我們", id: "about" },
                    ]
                }, {
                    title: "服學會議",
                    id: "meetings"
                }, {
                    title: "服學期刊",
                    id: "issues"
                }, {
                    title: "橫幅管理",
                    id: "banner"
                }, {
                    title: "表單下載",
                    id: "downloads"
                }, {
                    title: "相關辦法",
                    id: "rules"
                }
            ]
        }, {
            title: "使用者管理",
            id: "users",
            items: [
                {
                    title: "查詢學生",
                    id: "student"
                }, {
                    title: "安心就學",
                    id: "studyAtEase"
                }, {
                    title: "組織列表",
                    id: "group"
                }
            ]
        }
    ] : [
        {
            title: "學生學習護照",
            id: "passport",
            items: [{
                title: "已結案活動查詢",
                id: "activity/officeSearch"
            }]
        }
    ]

    //工讀生不能新增管理員
    if (userPermission.includes('admin') && isAdmin) {
        content[3]["items"].push({
            title: "管理員列表",
            id: "manager"
        })
    }

    const handleSubMenuExpand = (subMenu) => {
        setSubMenuOpen({
            ...subMenuOpen,
            [subMenu]: !subMenuOpen[subMenu]
        })
    };

    useEffect(() => {
        let newPage = props.location.pathname
        if (currentPage == null || currentPage !== newPage) {
            setCurrentPage(newPage);
        }
    }, [currentPage, props.location.pathname])

    let officeFocus = null;
    if (userOffices.length > 0) {
        officeFocus = userOffices.find(office => +params.officeId === +office["id"]);
        if (!isAdmin && officeFocus.kindno) {
            content[0].items.push({
                title: "預警名單",
                id: "warnings"
            })
            content.push({
                title: "使用者管理",
                id: "users",
                items: [{
                    title: "查詢學生",
                    id: "student"
                }]
            })
        }
    }

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            open={props.opened}
            style={{ flexShrink: 0, width: drawerWidth }}
            onClose={props.handleDrawerClose}
        >
            <div className={classes.PersonalMenu}>
                <div className="menuHeader">
                    <span>{isAdmin ? "服學後台" : officeFocus.name}</span>
                    <IconButton onClick={props.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <div className="menuBody">
                    {content.map((list, index) => (
                        <div key={index}>
                            <List>
                                <ListSubheader className="listTitle">{list.title}</ListSubheader>
                                {list.items.map(item => (
                                    <div key={item.id}>
                                        <ListItem
                                            button
                                            onClick={() => handleSubMenuExpand(item.id)}
                                            component={!item.subList ? Link : ""}
                                            to={!item.subList ? `${url}/${list.id}/${item.id}` : ""}
                                        >
                                            <ListItemText primary={item.title} />
                                            {item.subList ? (subMenuOpen[item.id] ? <ExpandLess /> : <ExpandMore />) : ""}
                                        </ListItem>
                                        {item.subList ? (
                                            <Collapse in={subMenuOpen[item.id]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {item.subList.map(subItem => (
                                                        <ListItem button
                                                            key={subItem.id}
                                                            className="nested"
                                                            component={Link}
                                                            to={`${url}/${list.id}/${item.id}/${subItem.id}`}
                                                        >
                                                            <ListItemText primary={subItem.title} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Collapse>
                                        ) : ""}
                                    </div>
                                ))}
                            </List>
                            {index < content.length - 1 ? <Divider /> : ""}
                        </div>
                    ))}
                </div>
                <div className="menuFooter">
                    <Button
                        className="logoutBtn"
                        variant="contained"
                        color="primary"
                        onClick={() => { props.history.push('/') }}
                    >
                        返回前台
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}

export default withRouter(PersonalMenu);

const useStyles = makeStyles((theme) => ({
    PersonalMenu: {
        width: "300px",
        height: "100%",
        maxWidth: drawerWidth,
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        overflowX: 'visible',
        "& .menuBody": {
            maxHeight: "calc(100% - 140px)",
            overflow: "auto",
            padding: "0 10px",
            "& .listTitle": {
                color: theme.palette.primary.main,
                backgroundImage: `linear-gradient(${theme.palette.background.paper} 70%, ${theme.palette.background.paper}00)`,
                fontWeight: "bold"
            },
            "& .MuiListItemText-root": {
                color: theme.palette.text.primary
            },
            "& .nested": {
                paddingLeft: "50px",
                color: "#555"
            },
            "& > *:last-child": {
                paddingBottom: "100px"
            }
        },
        "& .menuHeader": {
            padding: "20px 20px",
            display: "flex",
            backgroundColor: theme.palette.primary.main,
            alignItems: "center",
            fontWeight: "700",
            justifyContent: "space-between"
        },
        "& .menuFooter": {
            position: "absolute",
            zIndex: "5",
            bottom: "0",
            width: "100%",
            height: "130px",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            backgroundImage: `linear-gradient(${theme.palette.background.paper}00 , ${theme.palette.background.paper} 60%)`,
            "& .logoutBtn": {
                width: '70%',
                marginBottom: "10px",
                fontWeight: "bold",
                borderRadius: "12px",
                color: 'white',
                boxShadow: 'none'
            }
        }
    }
}));
