import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

import Container from '../../../components/layouts/Container';
import Loader from '../../../components/Loader';

import 'swiper/swiper.scss';

const NewsSwiper = props => {
    const classes = useStyles();
    const ref = useRef(null);
    const [slides, setSlides] = useState(null);

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext(500);
        }
    };

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev(500);
        }
    };

    useEffect(() => {
        if (props.cards) {
            let updatedSlides = [];
            for (let i = 0; i < props.cards.length; i += 2) {
                updatedSlides.push(
                    <SwiperSlide
                        key={`slide-${i}`}
                        className="slide"
                    >
                        {props.cards[i]}
                        {props.cards[i + 1]}
                    </SwiperSlide>
                );
            }
            setSlides(updatedSlides);
        }
    }, [props.cards]);

    return (
        <div className={classes.newsSwiper}>
            <Container className="controlPanelContainer">
                <div className="controlPanel" >
                    <ArrowBack
                        className="arrow"
                        onClick={goPrev}
                        color="primary"
                    />
                    <ArrowForward
                        className="arrow"
                        onClick={goNext}
                        color="primary"
                    />
                </div>
            </Container>
            {
                slides ?
                    <Swiper
                        ref={ref}
                        style={{ width: '100%' }}
                        loop
                        slidesPerView='auto'
                        spaceBetween={20}
                        breakpoints={{
                            768: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                                spaceBetween: 20
                            },
                        }}
                    >
                        {slides}
                    </Swiper>
                    :
                    <div className='loadingSwiper'>
                        <Loader />
                    </div>
            }
        </div>

    );
};

export default NewsSwiper;

// ----------------------------style-------------------------
const useStyles = makeStyles(theme => ({
    newsSwiper: {
        '& .slide': {
            marginLeft: 15,
            width: '80vw',
            height: '100%',
            display: "flex",
            flexWrap: "wrap",
            "& .newsCard": {
                margin: "15px 0",
            }
        },
        '& .controlPanel': {
            display: "none",
            position: 'relative'
        },
        '& .loadingSwiper': {
            width: '100%',
            height: '450px',
            backgroundColor: `${theme.palette.background.paper}AA`,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 15,
        }
    },
    [theme.breakpoints.up('md')]: {
        newsSwiper: {
            transform: 'translateX(-10px)',
            '& .slide': {
                width: "100%",
                marginLeft: 0
            },
            '& .controlPanel': {
                position: "absolute",
                right: 5,
                top: -50,
                width: 100,
                display: "flex",
                justifyContent: "space-around"
            },
            '& .arrow': {
                fontSize: 40,
                "&:hover": {
                    cursor: "pointer"
                },
                "&:active": {
                    filter: "brightness(150%)"
                }
            },
            "& .swiper-container": {
                padding: 10
            }
        },
    }
}));
