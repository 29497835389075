import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Skeleton from "@material-ui/lab/Skeleton";

const Banner = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.root}>
            <div className="imgArea">
                {props.cover ? (
                    <div className="bgImage"></div>
                ) : (
                    <Skeleton
                        variant="rect"
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    ></Skeleton>
                )}
            </div>
            <div className="infoArea">
                <h2 className="title">{props.title}</h2>
                <h4 className="info">{props.content}</h4>
                <Button
                    className="infoButton"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={props.loading}
                    component="a"
                    href={props.link}
                    target="_blank"
                >
                    了解更多
                </Button>

            </div>
        </div>
    );
};

export default Banner;

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        zIndex: 100,
        backgroundColor: theme.palette.background.paper,
        margin: "5px 5%",
        borderRadius: "15px",
        boxShadow: "0 0px 20px rgba(0, 0, 0, 0.2)",
        boxSizing: "border-box",
        overflow: "hidden",
        width: "87vw",
        "& .imgArea": {
            width: '100%',
            position: 'relative',
            maxWidth: 'none',
            paddingTop: '75%',
            marginRight: 0,
            "& .bgImage": {
                paddingTop: '75%',
                backgroundImage: props => `url(${props.cover})`,
                width: '100%',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: "absolute",
                left: 0,
                zIndex: 0,
                top: 0,
                objectFit: 'cover'
            },
        },
        "& .infoArea": {
            height: 200,
            flexShrink: 0,
            padding: "20px",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            "& p": {
                margin: "5px 0",
            },
            "& .title": {
                padding: 0,
                marginBottom: 10,
                margin: 0,
                fontWeight: 700,
                fontSize: 24,
                lineHeight: 1.25,
                // two line clampping
                display: '-webkit-box',
                lineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            },
            "& .info": {
                fontSize: 14,
                lineHeight: "1.5em",
                fontWeight: 300,
                height: '4rem',
                textAlign: 'justify',
                // three line clamping
                display: '-webkit-box',
                lineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
            },
            "& .infoButton": {
                width: "100%",
                borderRadius: "50px",
                margin: "15px 0 10px 0",
                fontSize: "14px",
                boxShadow: "none",
                color: "#fff",
                "&.Mui-disabled": {
                    backgroundColor: theme.palette.background.disabled
                }
            },
        },
    },
    [theme.breakpoints.up('md')]: {
        root: {
            height: `${530 * 3 / 4}px`,
            maxHeight: "70vh",
            position: 'relative',
            display: 'flex',
            flexDirection: "row-reverse",
            margin: "0",
            width: '95vw',
            maxWidth: "1300px",
            "& .imgArea": {
                flex: '530px',
                flexGrow: 0,
                padding: 0,
                height: '100%',
                "& .bgImage": {
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    right: 0
                },
            },
            "& .infoArea": {
                height: 'initial',
                flex: 4,
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "3rem",
                "& .title": {
                    fontSize: 24,
                    padding: 0
                },
                "& .info": {
                    fontSize: 16,
                    margin: "1.5rem 0",
                    height: "4.5rem"
                },
                "& .infoButton": {
                    marginTop: 40,
                    width: 150
                }
            },
        },
    },
    [theme.breakpoints.up('lg')]: {
        root: {
            height: `${600 * 3 / 4}px`,
            width: "90vw",
            "& .imgArea": {
                flex: '600px',
                flexGrow: 0
            },
            "& .infoArea": {
                "& .title": {
                    fontSize: 28
                },
            },
        }
    },
    [theme.breakpoints.up('xl')]: {
        root: {
            height: `${650 * 3 / 4}px`,
            width: "80vw",
            "& .imgArea": {
                flex: '650px',
                flexGrow: 0
            },
            "& .infoArea": {
                "& .title": {
                    fontSize: 32
                },
            },
        }
    },
}));
