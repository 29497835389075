import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core";

import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/components/effect-fade/effect-fade.scss";
import BannerService from "../../../services/BannerService";
import Banner from "./Banner";
import Skeleton from "@material-ui/lab/Skeleton";

SwiperCore.use([Pagination, Autoplay]);

const BannerSwiper = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState([]);
    useEffect(() => {
        BannerService.getBannerList()
            .then((res) => {
                let temp = [];
                for (let banner of res.data) {
                    temp.push(createBanner(banner));
                }
                setBanners(temp);
                setLoading(false);
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const createBanner = (banner) => {
        return (
            <SwiperSlide key={`slide-${banner.id}`} className='slide'>
                <Banner
                    title={banner.title}
                    content={banner.body}
                    link={banner.href}
                    cover={banner.cover.file_location}
                />
            </SwiperSlide>
        );
    };

    if (loading || banners.length <= 0) {
        return (
            <div className={classes.LoadingBanner}>
                <Banner
                    title={<Skeleton animation="wave" style={{
                        height: 60,
                        width: 250
                    }} />}
                    content={
                        < >
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </>
                    }
                    link=""
                    loading={loading}
                />
            </div>

        );
    }
    else {
        return (
            <Swiper
                className={classes.root}
                pagination={{ clickable: true }}
                slidesPerView={1}
                spaceBetween={30}
                centeredSlides={true}
                loop
                autoplay={{ delay: 5000 }}
            >
                {banners}
            </Swiper>
        );
    }
};

export default BannerSwiper;

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        height: "100%",
        "& .swiper-pagination-bullet": {
            opacity: 0.5,
            transition: '200ms',
            backgroundColor: theme.palette.primary.main,
            "&.swiper-pagination-bullet-active": {
                opacity: 1,
                width: 10,
                height: 10,
                transform: 'translateY(1px)'
            }
        },
        "&.swiper-container-horizontal > .swiper-pagination-bullets": {
            bottom: -5
        },
        "& .slide": {
            boxSizing: "border-box",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px 0 20px 0",
            backgroundPosition: "center",
            backgroundSize: "cover",
        },
    },
    LoadingBanner: {
        padding: "15px 0 20px 0",
        zIndex: 5,
        position: 'relative',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    [theme.breakpoints.up('lg')]: {
        root: {
            "& .slide": {
                padding: '30px 0'
            },
        },
        LoadingBanner: {
            padding: '30px 0'
        }
    }
}));
