import React from 'react';
import { makeStyles } from '@material-ui/core';

const HourChip = (props) => {
    const classes = useStyles(props);

    let chip = null;
    if (props.subType) {
        chip = (
            < >
                <div className="main withsub type">{`${props.mainType} ${props.hour}hr`}</div>
                <div className="sub type">{props.subType}</div>
            </>
        );
    }
    else {
        chip = <div className="main type">{`${props.mainType} ${props.hour}hr`}</div>
    }
    

    return (
        <div className={`${classes.hourChip} chip ${props.className}`}>
            {chip}
        </div>
    );
};

export default HourChip;

const useStyles = makeStyles(theme => ({
    hourChip: {
        display: 'flex',
        fontSize: "12.5px",
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        maxWidth: "100%",
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        backgroundColor: props => `${props.color}`,
        '& .type': {
            fontWeight: 700,
            borderRadius: '50px',
            padding: '3.5px 12px',
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        '& .main': {
            minWidth: "92px",
            boxSizing: "border-box",
            color: 'white',
            '&.withsub': {
                paddingRight: "0px"
            }
        },
        '& .sub': {
            backgroundColor: theme.palette.background.paper
        }
    }
}));
