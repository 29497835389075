import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

const NewsCard = (props) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    let doc = document.createElement('html');
    doc.innerHTML = props.content;
    let elements = doc.querySelectorAll('p,li');
    let contentText = "";
    for (let i = 0; i < elements.length; i++)
        contentText += elements[i].innerText;

    if (props.nodata) {
        return (
            <div
                className={`newsCard ${classes.root} ${props.className}`}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <div style={{ fontSize: 20 }}>請等待更多最新消息</div>
            </div>
        );
    }
    if (!props.loading) {
        return (
            <Link to={`/news/newsInfo/${props.id}`} className={`newsCard ${classes.root} ${props.className ? props.className : ""}`} >
                <div className='top'>
                    <div
                        className="label"
                        style={
                            props.category === "external"
                                ? { backgroundColor: "#4287f533", color: "#4287f5" }
                                : { backgroundColor: "#f5427533", color: "#f54275" }
                        }
                    >
                        {props.category === "external" ? "校外公告" : "校內公告"}
                    </div>
                    <h6 className='date' >
                        {props.date.slice(0, props.date.indexOf(' '))}
                    </h6>
                </div>
                <h4 className='title'>
                    {props.title}
                </h4>
                <div className="content">
                    {contentText}
                </div>
            </Link>
        );
    }
    else {
        return (
            <div className={`newsCard ${classes.root} ${props.className ? props.className : ""}`} >
                <div className='top'>
                    <Chip className='label loading' />
                    <div className='date loading' />
                </div>
                <div className='title loading' />
                <div className='content'>
                    <div className='loading' />
                    <div className='loading' />
                    <div className='loading' />
                </div>
            </div>
        );
    }
};

export default NewsCard;

// --------------------------------style-------------------------------------
const useStyles = makeStyles(theme => ({
    "@keyframes loading": {
        "0%": {
            backgroundPosition: "100% 50%"
        },
        "100%": {
            backgroundPosition: "0 50%"
        }
    },
    root: {
        "&> *": {
            color: theme.palette.text.primary,
        },
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: "0 0px 10px rgba(0, 0, 0, 0.2)",
        boxSizing: "border-box",
        margin: '10px 10px',
        padding: "16px 16px 10px 16px",
        width: "100%",
        borderRadius: "15px",
        flexDirection: "column",
        minHeight: 200,
        "& .loading": {
            position: 'relative',
            backgroundColor: theme.palette.divider,
            "&::after": {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: `linear-gradient(100deg, ${theme.palette.background.default}55 30%, ${theme.palette.background.glare}55 50%, ${theme.palette.background.default}55 70%)`,
                backgroundSize: '400%',
                animation: '$loading 1.2s ease-in-out infinite',
                top: 0,
                left: 0,
            },
        },
        '& .top': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            '& .date': {
                marginBottom: 0,
                marginLeft: 10,
                fontSize: 12,
                "&.loading": {
                    width: 70,
                    height: '1rem'
                }
            },
            "& .label": {
                width: "70px",
                height: "25px",
                borderRadius: 20,
                color: "white",
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 900
            },
            '& .label.loading': {
                width: '4rem',
                "&::after": {
                    borderRadius: 50
                },
            }
        },
        '& .title': {
            margin: "10px 0",
            fontWeight: 700,
            // two line clampping
            display: '-webkit-box',
            lineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            "&.loading": {
                width: '100%',
                height: '3rem'
            }
        },
        '& .content': {
            fontWeight: 300,
            width: '100%',
            marginBottom: 5,
            textAlign: 'justify',
            // three line clamping
            display: '-webkit-box',
            lineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            "& p": {
                margin: 0
            },
            "& ol, ul": {
                listStyle: 'none',
                margin: 'initial',
                padding: 'initial'
            },
            "& img, & h1, & h2": {
                display: 'none'
            },
            "&.loading": {
                backgroundColor: theme.palette.divider,
                width: '70%',
                height: '30px',
            },
            "& .loading": {
                width: '100%',
                height: '1rem',
                marginBottom: 5
            }
        }
    },
    '@media (min-width:768px)': {
        root: {
            margin: '10px 0px',
            boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
            "&:hover": {
                boxShadow: "0 0px 10px rgba(0, 0, 0, 0.2)",
                transform: "scale(1.02)",
                transition: "0.2s",
                cursor: "pointer"
            }
        }
    }
}));
