import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Loader from "../Loader";
import EnhancedTableToolbar from "./Toolbar";
import EnhancedTableHead from "./TableHead";

function descendingComparator(a, b, orderBy) {
    let a_item = a[orderBy];
    let b_item = b[orderBy];
    if (typeof a_item === "object" && typeof b_item === "object") {
        if (a_item.props && b_item.props) {
            if (b_item.props.text < a_item.props.text) {
                return -1;
            }
            if (b_item.props.text > a_item.props.text) {
                return 1;
            }
        }
    } else {
        if (b_item < a_item) {
            return -1;
        }
        if (b_item > a_item) {
            return 1;
        }
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(rows, comparator) {
    const stabilizedThis = rows.map((row, index) => [
        row.cells,
        index,
        row.data,
        row.buttons,
    ]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[2], b[2]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => ({
        cells: el[0],
        data: el[2],
        buttons: el[3],
    }));
}

const EnhancedTable = React.memo(
    (props) => {
        let rows = props.rows;
        let loading = props.loading;
        let headCells = props.headCells;

        const classes = useStyles(props);
        const [order, setOrder] = useState(props.order ? props.order : 'asc');
        const [orderBy, setOrderBy] = useState(props.orderBy ? props.orderBy : '');
        const [selected, setSelected] = useState([]);
        const [filterRows, setFilterRows] = useState(null);
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(10);
        const isBackStageTable = props.className ? props.className.split(" ").includes("backStageTable") : false;

        useEffect(() => {
            if (filterRows) {
                let table = document.getElementById("table");
                let noDataText = document.getElementById("noDataText");
                if (table) {
                    if (loading) {
                        table.classList.add("loading");
                    }
                    else {
                        table.classList.remove("loading")
                    }
                }
                if (noDataText) {
                    if (loading) {
                        noDataText.classList.add("loading");
                    }
                    else {
                        noDataText.classList.remove("loading");
                    }
                }
            }
        }, [loading, filterRows]);

        const handleSelectEmpty = () => {
            if (!props.holdingSelected || !props.holdingSelected.state) {
                setSelected([]);
            }
        };

        const handleRequestSort = (event, property) => {
            if (props.backend) {
                setOrder(order === "asc" ? "desc" : "asc");
            }
            else {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
            }
            setOrderBy(property);
            setPage(0);
        };

        const handleSelectAllClick = (event) => {
            if (event.target.checked) {
                const newSelecteds = filterRows.map((row) => row.data.id);
                setSelected(newSelecteds);
                return;
            }
            setSelected([]);
        };

        const handleClick = (event, id) => {
            const selectedIndex = selected.indexOf(id);
            let newSelected = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            }
            else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            }
            else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            }
            else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1)
                );
            }
            setSelected(newSelected);
        };

        const isSelected = (id) => selected.indexOf(id) !== -1;

        useEffect(() => {
            if (props.holdingSelected && props.holdingSelected.data) {
                setSelected(props.holdingSelected.data);
            }
        }, [props.holdingSelected])

        useEffect(() => {
            setFilterRows(rows);
        }, [rows]);

        const handleChangePage = (event, newPage) => {
            if (props.backend) {
                props.backend.handleChangePage(newPage);
            }
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            if (props.backend) {
                props.backend.handleChangeRowsPerPage(parseInt(event.target.value, 10));
            }
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        let emptyRows = null;
        if (rows) {
            if (props.backend) {
                emptyRows = rowsPerPage - rows.length;
            }
            else {
                emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
            }
        }

        if (rows && filterRows) {
            let colSpan = headCells.length;
            if (!props.noCheckbox) {
                colSpan++;
            }
            let target = filterRows;
            if (!props.sortDisable && !props.backend) {
                target = stableSort(filterRows, getComparator(order, orderBy));
            }
            if (!props.backend) {
                target = target.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            }
            return (
                <div id="tableRoot" className={`${classes.root} ${props.className ? props.className : ""} ${isBackStageTable ? "backStageDrawerOpened" : ""}`}>
                    <EnhancedTableToolbar
                        tableTitle={props.tableTitle}
                        numSelected={selected.length}
                        buttonData={props.buttonData}
                        selected={selected}
                        onEmptySelect={handleSelectEmpty}
                        headCells={headCells}
                        rows={rows}
                        filterRows={filterRows}
                        setFilterRows={setFilterRows}
                        titleBarControls={props.titleBarControls}
                        setPage={setPage}
                        handleClearFilter={props.backend ? props.backend.handleClearFilter : null}
                        backendSupported={props.backend != null}
                        noFilter={props.noFilter}
                        loading={props.loading}
                    />
                    <TableContainer className="tableContainer">
                        <Table className={classes.table} id="table" >
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={filterRows.length}
                                headCells={headCells}
                                loading={loading}
                                noCheckbox={!props.buttonData || props.noCheckbox}
                                sortDisable={props.sortDisable}
                                backendSort={props.backend ? props.backend.sort : null}
                            />
                            <TableBody>
                                {
                                    target.map((row, index) => {
                                        const isItemSelected = isSelected(row.data.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        let rowOnClick = (event, rowId) => { };
                                        if (props.rowClickable) {
                                            rowOnClick = (event, rowId) => handleClick(event, rowId);
                                        }
                                        else if (props.handleRowClick) {
                                            rowOnClick = (event, rowId) => { props.handleRowClick(rowId); };
                                        }
                                        return (
                                            <TableRow
                                                hover={!loading}
                                                color="primary"
                                                className="row"
                                                role="checkbox"
                                                checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.data.id}
                                                id={row.data.id}
                                                selected={isItemSelected}
                                                onClick={(event) => { rowOnClick(event, row.data.id); }}
                                            >
                                                {
                                                    !props.buttonData || props.noCheckbox ? null :
                                                        <TableCell padding="checkbox">
                                                            <Checkbox
                                                                onClick={(event) => { handleClick(event, row.data.id) }}
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                                disabled={loading}
                                                            />
                                                        </TableCell>
                                                }
                                                {props.showIndex ? <TableCell>{index + 1}</TableCell> : null}
                                                {Object.keys(row.cells).map(key => {
                                                    return <TableCell key={key}>{row.cells[key]}</TableCell>
                                                })}
                                                <div className='controlPanelWrapper'>
                                                    {
                                                        row.buttons ?
                                                            <td className="controlPanel">
                                                                {row.buttons}
                                                            </td> : null
                                                    }
                                                </div>
                                            </TableRow>
                                        );
                                    }
                                    )
                                }
                                {filterRows.length !== 0 && emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows, pointerEvents: 'none' }}>
                                        <TableCell colSpan={colSpan} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {filterRows.length === 0 ? <h5 id="noDataText" className='noDataText' align="center" style={{ height: 400 }}>無資料</h5> : null}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={props.backend ? props.backend.count : filterRows.length}
                        labelRowsPerPage="每頁列數:"
                        rowsPerPage={rowsPerPage}
                        labelDisplayedRows={({ from, to, count, page }) => `第${page + 1}頁 第${from}-${to}列 共${count !== -1 ? count : `more than ${to}`}列`}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            );
        }
        else {
            return (
                <div style={{ minHeight: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Loader />
                </div>
            );
        }
    },
    (prevProps, nextProps) => {
        if (prevProps.loading !== nextProps.loading) {
            return false;
        }
        if (prevProps.count !== nextProps.count) {
            return false;
        }
        if (prevProps.holdingSelected !== nextProps.holdingSelected) {
            return false;
        }
        if (prevProps.rows && nextProps.rows) {
            if (prevProps.rows.length !== nextProps.rows.length) {
                return false;
            }
            if (prevProps.rows && prevProps.rows.length > 0) {
                for (let i = 0; i < prevProps.rows.length; i++) {
                    if (
                        JSON.stringify(prevProps.rows[i].data) !==
                        JSON.stringify(nextProps.rows[i].data)
                    ) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    }
);

export default EnhancedTable;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        "&.backStageTable": {
            "& #sticky-toolbar": {
                width: '100%',
                right: 0,
                top: 0,
                left: "initial",
            },
            "&.backStageDrawerOpened #sticky-toolbar": {
                width: 'calc(100% - 240px)'
            }
        },
        "& .MuiTableContainer-root": {
            overflowX: 'auto',
            position: 'relative',
            "& .noDataText": {
                height: '30vh',
                display: 'flex',
                justifyContent: ' center',
                alignItems: 'center',
                color: theme.palette.text.primary
            }
        },
        "& .MuiTablePagination-root": {
            "& svg": {
                color: theme.palette.text.primary,
            },
            "& .Mui-disabled svg": {
                color: theme.palette.text.disabled
            }
        }

    },
    "& .MuiTableContainer-root": {
        overflowX: "auto",
        position: "relative",
    },
    table: {
        minWidth: (props) => {
            if (props.minWidth) return props.minWidth;
            else return 750;
        },
        margin: 0,
        boxShadow: "none",
        display: "table",
        width: "100%",
        "& .MuiTableCell-root": {
            maxWidth: "150px",
            minWidth: 'auto'
        },
        "& .MuiTableRow-root.Mui-selected, & .MuiTableRow-root.Mui-selected:hover":
        {
            backgroundColor: `${theme.palette.primary.main}33`,
        },
        "& .MuiCheckbox-root": {
            color: theme.palette.text.secondary,
            "&.Mui-checked": {
                color: theme.palette.primary.main,
            },
        },
        "& .MuiTableSortLabel-root": {
            color: theme.palette.text.primary
        },
        "& tbody tr": {
            position: "relative",
            transform: "scale(1,1)",
            width: "100%",
            "& .controlPanel": {
                display: "none",
            },
            "& .controlPanelWrapper": {
                position: 'relative',
            },
            "&:hover": {
                cursor: (props) => `${!props.rowClickable && !props.handleRowClick ? "default" : "pointer"}`,
                backgroundColor: theme.palette.action.hover,
                "& .controlPanel": {
                    display: "flex",
                    position: "absolute",
                    top: "-15px",
                    right: "1vw",
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 50,
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    "& .controlBtn": {
                        borderRadius: 50,
                        fontSize: 14,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.5rem 1rem",
                        whiteSpace: 'nowrap',
                        "& .MuiSvgIcon-root": {
                            marginRight: "0.5rem",
                            fontSize: "1.2em",
                        },
                        "& .MuiTouchRipple-root": {
                            borderRadius: 50,
                        },
                    },
                },
            },
        },
        "&.loading": {
            "&::after": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                background: `linear-gradient(100deg, ${theme.palette.background.default}55 30%, ${theme.palette.background.glare}55 50%, ${theme.palette.background.default}55 70%)`,
                backgroundSize: "400%",
                animation: "$loading 1.2s ease-in-out infinite",
                top: 0,
                left: 0,
            },
            "& input, & button": {
                pointerEvents: "none",
            },
        },
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    [theme.breakpoints.up("md")]: {
        root: {
            // "& .MuiTableContainer-root": {
            //     overflowX: 'visible',
            // },
        },
        table: {
            overflowX: "visible",
        },
    },
    "@keyframes loading": {
        "0%": {
            backgroundPosition: "100% 50%",
        },
        "100%": {
            backgroundPosition: "0 50%",
        },
    },
}));
