import React, { useState } from 'react'
import { Editor } from "@tinymce/tinymce-react";
import Loader from "../../../components/Loader";
import FileService from "../../../services/FileService";

const MyEditor = (props) => {
    const [loading, setLoading] = useState(true);

    return (<>
        <Editor
            value={props.value}
            disabled={props.disabled}
            onInit={() => setLoading(false)}
            init={{
                height: 400,
                language: "zh_TW",
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen imagetools",
                    "insertdatetime media table paste code help wordcount hr",
                ],
                placeholder: props.placeholder ?? "",
                file_picker_callback: function (callback) {
                    var input = document.createElement("input");
                    input.setAttribute("type", "file");
                    input.onchange = function () {
                        var file = this.files[0];
                        const body = new FormData();
                        body.append("file", file);
                        FileService.postFile(body).then((res) => {
                            callback(res.data.file_url, { text: file.name });
                        });
                    };

                    input.click();
                },
                toolbar:
                    "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link | help | hr",
            }}
            onEditorChange={(content) => props.onChange(props.id, content)}
        />
        {loading && (
            <div className="loadingBlock">
                <Loader/>
            </div>
        )}
    </>)
}

export default MyEditor;
