import { useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import { noty } from "../notification";

const useError = (props) => {
    const [error, setError] = useState();
    const dispatch = useDispatch();
    const history = useHistory();

    const errorHandler = useCallback((error) => {
        setError(error);
        if(error.response){
            switch(error.response.status){
                case 422:
                    let errMsg = `${error.message}<ul>`;
                    if(error.response.data.errors){
                        for(let values of Object.values(error.response.data.errors)){
                            errMsg += `<li>${values[0]}</li>`;
                        }
                    }
                    errMsg += `</ul>`;
                    noty('error', `${errMsg}`);
                    break;
                case 401:
                case 403:
                    noty('warning', "身分驗證過時，請重新登入");
                    dispatch(authActions.logout());
                    history.push('/');
                    break;
                default:
                    noty('error', error.message);
                    break;
            }
        }else{
            noty('error', error.message);
        }
    }, [dispatch, history]);

    return {
        error: error,
        errorHandler: errorHandler
    };
};

export default useError;
