import axios from "./instance/axios";

const HourService = {
    getYearList: async () => {
        try {
            const response = await axios.get("/year");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteYear: async (year) => {
        try {
            const response = await axios.delete(`/admin/year/${year}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postYear: async (data) => {
        try {
            const response = await axios.post("/admin/year", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getGroupList: async () => {
        try {
            const response = await axios.get("/hour/group");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postGroup: async (data) => {
        try {
            const response = await axios.post("/admin/hour/group", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getGroup: async (id) => {
        try {
            const response = await axios.get(`/hour/group/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteGroup: async (id) => {
        try {
            const response = await axios.delete(`/admin/hour/group/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putGroup: async (id, data) => {
        try {
            const response = await axios.put(`/admin/hour/group/${id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postType: async (data) => {
        try {
            const response = await axios.post("/admin/hour/type/", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getType: async (id) => {
        try {
            const response = await axios.get(`/hour/type/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putType: async (id, data) => {
        try {
            const response = await axios.put(`/admin/hour/type/${id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteType: async (id) => {
        try {
            const response = await axios.delete(`/admin/hour/type/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getPlanList: async () => {
        try {
            const response = await axios.get("/hour/plan");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postPlan: async (data) => {
        try {
            const response = await axios.post("/admin/hour/plan", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getPlan: async (id) => {
        try {
            const response = await axios.get(`/hour/plan/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getYearPlan: async (year) => {
        try {
            const response = await axios.get(`/hour/plan/year/${year}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deletePlan: async (id) => {
        try {
            const response = await axios.delete(`/admin/hour/plan/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postRelation: async (data) => {
        try {
            const response = await axios.post("/admin/hour/relation", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getRelationList: async () => {
        try {
            const response = await axios.get("/hour/relation");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getYearRelation: async (year) => {
        try {
            const response = await axios.get(`/hour/relation/${year}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postRequirement: async (data) => {
        try {
            const response = await axios.post("/admin/hour/requirement", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putRequirement: async (id, data) => {
        try {
            const response = await axios.put(
                `/admin/hour/requirement/${id}`,
                data
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteRequirement: async (id) => {
        try {
            const response = await axios.delete(
                `/admin/hour/requirement/${id}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStudentDetail: async (id) => {
        try {
            const response = await axios.get(`/student/hour/detail/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStudentDetailByAdmin: async (userId, hourGroupId) => {
        try {
            const response = await axios.get(
                `/admin/hour/detail/${userId}/${hourGroupId}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStudentDetailByOrg: async (orgId, userId, hourGroupId) => {
        try {
            const response = await axios.get(
                `/organization/hour/detail/${orgId}/${userId}/${hourGroupId}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getLatestRelation: async () => {
        try {
            const response = await axios.get("hour/relation/newest");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getNotificationList: async () => {
        try {
            const response = await axios.get(`/admin/hour/notification`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postNotification: async (data) => {
        try {
            const response = await axios.post(`/admin/hour/notification`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putNotification: async (id, data) => {
        try {
            const response = await axios.put(
                `/admin/hour/notification/${id}`,
                data
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteNotification: async (id) => {
        try {
            const response = await axios.delete(
                `/admin/hour/notification/${id}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAwardEmail: async () => {
        try {
            const response = await axios.get(`/admin/hour/email/goldsilver`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getWarningEmail: async () => {
        try {
            const response = await axios.get(`/admin/hour/email/graduation`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postEmail: async (data) => {
        try {
            const response = await axios.post(`/admin/hour/email`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getUpdateNameList: async (type, time) => {
        try {
            const response = await axios.get(
                time
                    ? `/admin/hour/list/generate?category=${type}&before=${time}`
                    : `/admin/hour/list/generate?category=${type}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getNameList: async (type, order) => {
        try {
            const response = await axios.get(
                `/admin/hour/list?category=${type}&order_by=${order}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getMajorName: async () => {
        try {
            const response = await axios.get(`/office`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getOrgWarning: async (id) => {
        try {
            const response = await axios.get(
                `/organization/hour/list?organization_id=${id}&category=graduation`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default HourService;
