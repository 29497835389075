import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-211420033-1';

export const initGA = () => {
    ReactGA.initialize(TRACKING_ID);
};

export const trackingPageGA = (page) => {
    ReactGA.pageview(page);
};

export const sendWebVitals = ({ id, name, value }) => {
    ReactGA.event({
        category: 'Web Vitals',
        action: name,
        label: id,
        value: Math.round(name === 'CLS' ? value * 1000 : value),
        nonInteraction: true,
    });
}

export const eventGA = (categoryName, eventName) => {
    ReactGA.event({
        category: categoryName, // Required
        action: eventName, // Required
        label: 'labelName',
        value: 10,
        nonInteraction: false,
    });
};
