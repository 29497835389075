import React, { lazy } from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

const ActivityVerify = lazy(() => import("./Verify"));
const EventApply = lazy(() => import("../../../Apply/EventApply"));
const HourApply = lazy(() => import("../../../Apply/HourApply"));
const ActivityInfo = lazy(() => import("../../../ActivityInfo"));
const ActivityManagement = lazy(() => import("./Management"));
const HourVerify = lazy(() => import("./Hour"));
const Individual = lazy(() => import("./Individual"));
const OfficeSearch = lazy(() => import("./OfficeSearch"));

const Activities = props => {
    let { path, url } = useRouteMatch();
    const isAdmin = props.location.pathname.split("/")[1] === "admin";
    console.log(`${path}/officeSearch`)
    if (isAdmin) {
        return (<>
            <Route path={path} exact>
                <Redirect to={`${url}/application`} />
            </Route>
            <Route path={`${path}/apply`} component={EventApply} />
            <Route path={`${path}/individual`} exact component={Individual} />
            <Route path={`${path}/individual/:id`} component={HourApply} />
            <Route path={`${path}/application`} exact component={ActivityVerify} />
            <Route path={`${path}/application/:id`} component={EventApply} />
            <Route path={`${path}/hour`} exact component={HourVerify} />
            <Route path={`${path}/hour/:id`} component={ActivityInfo} />
            <Route path={`${path}/management`} component={ActivityManagement} />
        </>);
    }
    else {
        return (<>
            <Route path={path} exact>
                <Redirect to={`${url}/officeSearch`} />
            </Route>
            <Route path={`${path}/officeSearch`} component={OfficeSearch} />
        </>)
    }
}

export default Activities;
