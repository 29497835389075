import ActivityCard from '../components/ActivityCard';
import HourChip from '../components/HourChip';
import { getHrInfo } from './hourTypes';
import { getDate, getTime } from '../util/time';

export const createActivityCard = (activity, hourRelation = null, hourGroupId = 1, props = {}) => {
    const { noProgressBar, children, onClick, noHover } = props;
    const chips = [];
    let hourGroup = null;
    let imgCategory = null;
    if (activity.hour && activity.hour.length) {
        hourGroup = activity.hour.find(hrGroup => hrGroup['hour_group_id'] === hourGroupId);

        const imgHourCert = activity.hour[0].hour_certifications.length > 0 ? activity.hour[0].hour_certifications[0] : null;
        if (imgHourCert) {
            let hourData = hourRelation.find(item => item.hour_type_id === imgHourCert.hour_type_id);
            if (hourData && hourData.parent_id) {
                imgCategory = hourRelation.find(item => item.hour_type_id === hourData.parent_id).hour_type;
            }
            else {
                imgCategory = imgHourCert.name;
            }
        }
    }

    if (hourRelation && hourGroup) {
        for (let hourCert of hourGroup.hour_certifications) {
            let hourData = hourRelation.find(item => item.hour_type_id === hourCert.hour_type_id);
            let mainType = hourCert.name;
            let subType = null;
            if (hourData && hourData.parent_id) {
                mainType = hourRelation.find(item => item.hour_type_id === hourData.parent_id).hour_type;
                subType = hourCert.name;
            }
            chips.push(
                <HourChip
                    key={hourCert.hour_type_id}
                    className="chip"
                    hour={hourCert.hour}
                    mainType={mainType}
                    subType={subType}
                    color={getHrInfo(mainType)['color']}
                />
            );
        }
    } else {
        chips.push(
            <div key={-1} style={{
                display: 'flex',
                fontSize: "12.5px",
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50px',
                maxWidth: "100%",
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                backgroundColor: "#777",
                padding: '4px 12px',
                color: "white"
            }}>無此類別時數</div>
        );
    }
    const startTime = new Date(activity.start_time.replace(/-/g, '/')).getTime();
    const endTime = new Date(activity.end_time.replace(/-/g, '/')).getTime();
    let crossDate = getDate(startTime) !== getDate(endTime);
    const activityTime = crossDate ?
        `${getDate(startTime)}~${getDate(endTime)}` :
        `${getDate(startTime)} ${getTime(startTime)}~${getTime(endTime)}`;

    return (
        <ActivityCard
            className={`${noHover ? 'noHover' : ''}`}
            id={activity.id}
            key={activity.id}
            name={activity.name}
            place={activity.location}
            time={activityTime}
            imgURL={activity.cover ? activity.cover.file_location : getHrInfo(imgCategory ? imgCategory : null).defaultImg}
            max={activity.maximum}
            signupCount={activity.signup_count ? activity.signup_count : 0}
            chips={chips}
            noProgressBar={noProgressBar}
            onClick={onClick}
            enrolledActivities={props.enrolledActivities}
        >
            {children}
        </ActivityCard>
    );
}

export const getStatusText = (status, individual) => {
    if (!individual) {
        switch (status) {
            case 0:
                return '草稿';
            case 1:
                return '活動申請待審核';
            case 2:
                return '活動申請駁回';
            case 3:
                return '活動申請通過';
            case 4:
                return '活動已結束 等待時數審核';
            case 5:
                return '主辦單位送出時數申請';
            case 6:
                return '時數申請駁回';
            case 7:
                return '時數核准 活動結案';
            default:
                return '無此狀態';
        }
    }
    else {
        switch (status) {
            case 0:
                return '草稿';
            case 1:
                return '個人申請待審核';
            case 2:
                return '個人申請駁回';
            case 3:
                return '個人申請通過';
            default:
                return '無此狀態';
        }
    }
}
