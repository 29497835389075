import Noty from 'noty';

export const noty = (msgType, msg) => {
    new Noty({
        type: msgType,
        text: msg,
        layout: 'topRight',
        theme: 'nest',
        timeout: '2000',
        progressBar: true,
        closeWith: ['click']
    }).show();
}
