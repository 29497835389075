import { createSlice } from "@reduxjs/toolkit";
import UserService from "../services/UserService";
import HourService from "../services/HourService";
import { noty } from "../util/notification";
import { calcRemainTime } from '../util/time';

let logoutTimer;

const initialAuthState = {
    token: "",
    isLoggedIn: false,
    data: {}
};

const authSlice = createSlice({
    name: "authentication",
    initialState: initialAuthState,
    reducers: {
        logout(state) {
            state.token = null;
            state.isLoggedIn = false;
            state.scope = [];
            state.data = {};
            localStorage.removeItem("token");
            localStorage.removeItem("expirationTime");
            localStorage.removeItem("permission");
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
        },
        login(state, action) {
            state.token = action.payload.token;
            state.isLoggedIn = true;
        },
        setUserData(state, action) {
            state.data = action.payload.data;
        },
        setUserEnrolledActivities(state, action) {
            state.data.enrolledActivities = action.payload;
        },
        addUserEnrolledActivity(state, action) {
            state.data.enrolledActivities[action.payload.id] = action.payload;
        }
    },
});

export const loginAndGetUserData = (payload, callback) => {
    localStorage.setItem("token", payload.token);
    localStorage.setItem("expirationTime", payload.expTime);
    return async (dispatch) => {
        dispatch(
            authSlice.actions.login({
                token: payload.token
            })
        );
        logoutTimer = setTimeout(() => {
            noty("warning", "驗證過時，請重新登入");
            dispatch(authSlice.actions.logout());
            clearTimeout(logoutTimer);
            if (payload.history) payload.history.replace('/');
        }, calcRemainTime(payload.expTime));
        UserService.getInfo().then(userRes => {
            let userData = userRes.data;
            dispatch(authSlice.actions.setUserData({ data: { ...userData } }));
			if(userData['permission'].includes('student')){
                HourService.getStudentDetail(1)
            }
            localStorage.setItem("permission", userData.permission);
            callback();
        }).catch(err => callback(err));
    };
};

export const authActions = authSlice.actions;

export default authSlice.reducer;
