import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import ScrollToTop from "./ScrollToTop";
import { initGA, trackingPageGA } from '../../../util/reactGA';

const Page = (props) => {
    const title = props.title ? props.title + " | 中央大學服務學習發展中心" : "中央大學服務學習發展中心";

    useEffect(() => {
        initGA();
        trackingPageGA(window.location.pathname);
    }, []);

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            className={props.className}
            variants={variants.fadeInUp}
        >
            <Helmet>
                <meta property="og:title" content={title} />
                <meta property="og:description" content="中央大學服務學習發展中心以「服務學習」必修課程為經，並與校園活動「品格教育」為緯，融合「正式課程」與「非正式課程」， 提供更全面的情意學習環境。並從服務學習教育、 班級經營與成長、社會關懷與服務到服務學習， 不僅希望能增進學生學習的廣度， 同時能增加學習的深度，從認知、情意、技術、體驗服務與學習的教學理念， 倡導 推動「服務、學習」的理念已成為極為適合與重要的教學策略。" />
                <title>{title}</title>
            </Helmet>
            <ScrollToTop />
            {props.children}
        </motion.div>
    );
};

export default Page;

export const variants = {
    fadeInUp: {
        initial: {
            opacity: 0,
            y: 20
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                ease: 'easeOut',
            }
        },
        exit: {
            y: -20,
            opacity: 0,
            transition: {
                ease: 'easeIn',
            }
        }
    }
};
