import axios from './instance/axios';

const BannerService =  {
    getBannerList: async () => {
        try{
            const response = await axios.get("/banner");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getBanner: async (id) => {
        try{
            const response = await axios.get(`/banner/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postBanner: async (data) => {
        try{
            const response = await axios.post("/admin/banner", data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    deleteBanner: async (id) => {
        try{
            const response = await axios.delete(`/admin/banner/${id}`)
            return response.data
        } catch (error) {
            throw error
        }
    },
    putBannerOrder: async (data) => {
        try{
            const response = await axios.put("/admin/banner", data)
            return response.data
        } catch (error) {
            throw error
        }
    }
}

export default BannerService;