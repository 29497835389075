import React, { Suspense, lazy } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const Student = lazy(() => import("./Student"));
const Manager = lazy(() => import("./Manager"));
const Group = lazy(() => import("./Group"));
const StudyAtEase = lazy(() => import("./StudyAtEase"));

const User = (props) => {
    const isAdmin = props.location.pathname.split('/')[1] === 'admin';
    let { path } = useRouteMatch();
    const classes = useStyles();
    return (
        <Suspense fallback={<div className={classes.Suspense}></div>}>
            <Switch>
                {isAdmin ? (<>
                    <Route path={`${path}/student`} component={Student} />
                    <Route path={`${path}/studyAtEase`} component={StudyAtEase} />
                    <Route path={`${path}/group`} component={Group} />
                    <Route path={`${path}/manager`} component={Manager} />
                </>) : (<>
                    <Route path={`${path}/student`} component={Student} />
                </>)}
            </Switch>
        </Suspense>
    );
}

export default User;

const useStyles = makeStyles((theme) => ({
    Suspense: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.default
    }
}));
