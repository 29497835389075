import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentTheme: localStorage.getItem('appTheme') || 'light',
    activityDeckLayout: 'card',
}

const themeSlice = createSlice({
    name: 'theme',
    initialState: initialState,
    reducers: {
        toggle(state) {
            let themeName = (state.currentTheme === 'light') ? 'dark' : 'light';
            localStorage.setItem('appTheme', themeName);
            state.currentTheme = themeName;
        },
        changeToCardLayout(state) {
            state.activityDeckLayout = 'card';
        },
        changeToTableLayout(state) {
            state.activityDeckLayout = 'table';
        }
    },
});

export const themeActions = themeSlice.actions;

export default themeSlice.reducer;
