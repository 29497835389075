import axios from "./instance/axios";

const AnnouncementService = {
    getAnnouncementList: async (page, all = false) => {
        try {
            let response = null;
            if (all) {
                response = await axios.get(`/announcement`);
            } else {
                response = await axios.get(`/announcement?page=${page}`);
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAdminAnnouncementList: async (page) => {
        try {
            const response = await axios.get(`/admin/announcement?page=${page}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAnnouncement: async (id) => {
        try {
            const response = await axios.get(`/announcement/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postAdminAnnouncement: async (data) => {
        try {
            const response = await axios.post("/admin/announcement", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putAdminAnnouncement: async (id, data) => {
        try {
            const response = await axios.put(`/admin/announcement/${id}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postScholarApply: async (data) => {
        try {
            const response = await axios.post("/student/scholarship", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteAnnouncement: async (id) => {
        try {
            const response = await axios.delete(`/admin/announcement/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStuScholar: async (id) => {
        try {
            const response = await axios.get(`/student/scholarship/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAdminScholar: async (id) => {
        try {
            const response = await axios.get(`/admin/scholarship/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default AnnouncementService;
