//third party packages
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withWidth } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';

//components
import Container from "../../components/layouts/Container";
import NewsCard from "./NewsCard";
import NewsSwiper from "./NewsSwiper";
import ActivitiesSwiper from "./ActivitiesSwiper";
import BannerSwiper from "./BannerSwiper";
import PageLoader from "../../components/Loader/PageLoader";
import Page from "../../components/layouts/Page";

//services and utilities
import { createActivityCard } from "../../util/activity";
import useError from "../../util/hooks/use-error";
import { loginAndGetUserData } from "../../store/auth";
import UserService from "../../services/UserService";
import AnnouncementService from "../../services/AnnouncementService";
import ActivityService from "../../services/ActivityService";

//images
import decoDots from "../../assets/images/decoDots.svg";
import decoCircle from "../../assets/images/decoCircle.svg";
import ActivityCard from "../../components/ActivityCard";
import { getHrInfo } from "../../util/hourTypes";

const Home = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { errorHandler } = useError();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [activityCards, setActivityCards] = useState(null);
    const [newsCards, setNewsCards] = useState(null);

    const hourRelation = useSelector(state => state.hour.latestRelation);
    const userPermission = localStorage.getItem('permission');

    useEffect(() => {
        const loginHandler = (payload) => {
            dispatch(loginAndGetUserData(payload, (err = null) => {
                if (err) errorHandler(err);
                setLoading(false);
            }))
        };
        if (props.location.pathname.split("/").pop() === "callback") {
            const queryParams = new URLSearchParams(props.location.search);
            const code = queryParams.get('code');
            const path = queryParams.get('state');
            if (code) {
                setLoading(true);
                UserService.login(code).then((tokenData) => {
                    loginHandler({
                        token: tokenData.token,
                        expTime: new Date(Date.now() + 2 * 60 * 60 * 1000).toISOString(),
                    });
                }).catch((err) =>
                    errorHandler(err)
                ).finally(() => {
                    setLoading(false);
                    if(path){
                        props.history.push(path)
                    }else{
                        queryParams.delete('code');
                        props.history.replace({ search: queryParams.toString() })
                    }
                });
            }
        }

        AnnouncementService.getAnnouncementList(null, true).then((res) => {
            const data = res.data;
            const cards = [];
            if (res.data.length < 6) {
                for (let i = 0; i < data.length; i++) {
                    const news = data[i];
                    cards.push(
                        <NewsCard
                            key={news.id}
                            date={news.announcement_at}
                            content={news.content}
                            title={news.title}
                            category={news.category}
                            id={news.id}
                        />
                    );
                }
                for (let i = 0; i < 6 - data.length; i++) {
                    cards.push(
                        <NewsCard
                            key={i}
                            nodata
                        />
                    );
                }
            }
            else {
                for (let i = 0; i < Math.min(18, 6 * Math.floor(res.data.length / 6)); i++) {
                    const news = res.data[i];
                    cards.push(
                        <NewsCard
                            key={news.id}
                            date={news.announcement_at}
                            content={news.content}
                            title={news.title}
                            category={news.category}
                            id={news.id}
                        />
                    );
                }
            }
            setNewsCards(cards);
        }).catch(err => errorHandler(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorHandler]);

    useEffect(() => {
        const setCards = (data, enrolledActivities = null) => {
            let cards = [];
            if (data.length < 8) {
                for (let i = 0; i < data.length; i++) {
                    const activity = data[i];
                    cards.push(createActivityCard(activity, hourRelation, 1, { enrolledActivities }));
                }
                for (let i = 0; i < 8 - data.length; i++) {
                    cards.push(<ActivityCard key={`nodata${i}`} noData={true} imgURL={getHrInfo().defaultImg} />);
                }
            } else {
                for (let i = 0; i < Math.min(20, data.length); i++) {
                    const activity = data[i];
                    cards.push(createActivityCard(activity, hourRelation, 1, { enrolledActivities }));
                }
            }
            setActivityCards(cards);
        }
        if (hourRelation) {
            ActivityService.getLatestActivityList().then(res => {
                let activityList = res.data;
                if (userPermission && userPermission.includes('student')) {
                    ActivityService.getStudentEnrolledActivityList().then(res => {
                        let enrolledActivities = {};
                        res.data.forEach((activity) => (enrolledActivities[activity.id] = activity));
                        setCards(activityList, enrolledActivities);
                    });
                }
                else {
                    setCards(activityList);
                }
            }).catch(err => { errorHandler(err); console.log(err); });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hourRelation, errorHandler]);

    return (
        <Page>
            <div className={classes.root}>
                <div className="background"></div>
                <div className="bannerArea">
                    <svg
                        className="bottomDeco"
                        preserveAspectRatio="xMidYMax meet"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 1300 200"
                    >
                        <path
                            fill={theme.palette.background.default}
                            d="M4280.577-22463.26s-124.546-93.437-319.172-90.654-380.219,137.346-578.244,135.979-471.16-85.771-471.16-85.771v153.111H4280.577Z"
                            transform="translate(-2912 22553.975)"
                        />
                    </svg>
                    <div className="banners">
                        <BannerSwiper />
                    </div>
                </div>
                <div className="activitiesArea">
                    <img className="decoDots" src={decoDots} alt="" />
                    <Container className="titleContainer">
                        <div className="title">
                            <h2 className="sub">Latest Activities</h2>
                            <h1 className="main">
                                <span onClick={['xs', 'sm', 'md'].includes(props.width) ? () => props.history.push('/activities') : null}>
                                    {['xs', 'sm', 'md'].includes(props.width) ? '最新認證活動' : '最精采有趣的活動等你參加'}
                                </span>
                                <div className="moreInfoIcon" onClick={() => { props.history.push('/activities') }}>
                                    <ArrowForwardIcon className="first" />
                                    <ArrowForwardIcon className="second" />
                                </div>
                            </h1>
                        </div>
                    </Container>
                    <ActivitiesSwiper cards={activityCards} />
                    <img className="decoCircle" src={decoCircle} alt="" />
                </div>
                <div className="newsArea">
                    <Container className="titleContainer">
                        <div className="title">
                            <h2 className="sub">News</h2>
                            <h1 className="main">
                                <span onClick={['xs', 'sm', 'md'].includes(props.width) ? () => props.history.push('/news') : null}>
                                    最新消息
                                </span>
                                <div className="moreInfoIcon" onClick={() => { props.history.push('/news') }}>
                                    <ArrowForwardIcon className="first" />
                                    <ArrowForwardIcon className="second" />
                                </div>
                            </h1>
                        </div>
                    </Container>
                    <Container className="bigScreenNewsSwiperContainer">
                        <NewsSwiper cards={newsCards} />
                    </Container>
                </div>
            </div>
            {loading && <PageLoader />}
        </Page>
    );
};

export default withWidth()(Home);

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: 'hidden',
        "& .infoButton": {
            maxWidth: 150,
            borderRadius: 50,
            fontWeight: "bold",
            color: "#fff",
            marginBottom: 20,
        },
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        "& .title": {
            "& .sub": {
                margin: 0,
                fontWeight: "normal",
                lineHeight: "1em",
                fontSize: 16,
                color: theme.palette.primary.main,
                paddingLeft: 5
            },
            "& .main": {
                margin: "10px 0 20px 0",
                fontSize: 30,
                display: 'flex',
                alignItems: 'center',
                marginTop: 5,
                marginBottom: 10,
                "& span": {
                    display: 'flex',
                    lineHeight: 1
                },
                "& .moreInfoIcon": {
                    paddingLeft: 5,
                    paddingTop: 5,
                    "& svg": {
                        fontSize: 27,
                        "&.first": {
                            display: 'none'
                        }
                    }
                }
            },
        },
        "& .background": {
            position: 'absolute',
            backgroundImage: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            width: "100%",
            height: "1000px",
            top: -100
        },
        "& .bannerArea": {
            position: "relative",
            paddingTop: "55px",
            overflow: "hidden",
            "&:after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "100px",
                backgroundColor: theme.palette.background.default,
                position: "absolute",
                bottom: "-2px",
            },
            "& .bottomDeco": {
                width: "100vw",
                position: "absolute",
                left: 0,
                bottom: 92,
            },
        },
        "& .activitiesArea": {
            backgroundColor: theme.palette.background.default,
            position: "relative",
            overflow: "hidden",
            padding: "80px 0",
            paddingBottom: 50,
            "& .decoDots": {
                position: "absolute",
                top: 80,
                width: "initial",
                margin: "0 0 0 auto",
                right: -300,
                zIndex: 0
            },
            "& .decoCircle": {
                position: "absolute",
                width: "initial",
                margin: "0 0 0 auto",
                left: -50,
                bottom: 0
            },
            "& .titleContainer": {
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 5
            },
        },
        "& .newsArea": {
            backgroundColor: theme.palette.background.default,
            position: "relative",
            height: "100%",
            padding: "4rem 0 4rem 0",
            overflow: "hidden",
            "& .decoDots": {
                position: "absolute",
                top: 0,
                width: "70%",
                right: "-40%",
            },
            "& .titleContainer": {
                display: 'flex',
                alignItems: 'flex-end',
                "& .main": {
                    marginBottom: 5
                }
            },
            "& .bigScreenNewsSwiperContainer": {
                position: "relative",
                padding: 0,
            },
            "&:before": {
                position: "absolute",
                zIndex: 0,
                content: '""',
                display: "block",
                width: "200%",
                height: 450,
                backgroundColor: "#FF682B",
                opacity: 0.2,
                borderRadius: 50,
                bottom: 0,
                left: "-120%",
                transform: "rotate(-15deg)",
            },
        },
    },
    [theme.breakpoints.up("lg")]: {
        root: {
            "& .title": {
                "& .sub": {
                    fontSize: 20
                },
                "& .main": {
                    fontSize: 40,
                    "& .moreInfoIcon": {
                        paddingLeft: 10,
                        paddingTop: 5,
                        "& svg": {
                            fontSize: 35,
                            "&.first": {
                                display: 'initial',
                                opacity: 0,
                                transform: 'translateX(-10px)',
                                transition: '0.5s',
                            },
                            "&.second": {
                                transform: 'translateX(-40px)',
                                transition: '0.5s',
                            }
                        },
                        "&:hover": {
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                            "& svg": {
                                "&.first": {
                                    opacity: 1,
                                    transform: 'translateX(0px)'
                                },
                                "&.second": {
                                    transform: 'translateX(-15px)'
                                }
                            },
                        }
                    }
                },
            },
            "& .infoButton": {
                maxWidth: 'initial',
                maxHeight: 50,
                marginLeft: 30
            },
            "& .bannerArea": {
                "& .bottomDeco": {
                    bottom: -2,
                },
                "&:after": {
                    display: "none",
                },
            },
            "& .banners": {
                display: "flex",
                justifyContent: "center",
            },
            "& .activitiesArea": {
                padding: "80px 0 50px 0",
                "& .decoDots": {
                    position: "absolute",
                    top: 120,
                    width: "initial",
                    margin: "0 0 0 auto",
                    right: -180,
                },
                "& .decoCircle": {
                    position: "absolute",
                    bottom: 50,
                    width: "initial",
                    margin: "0 0 0 auto",
                    left: 30,
                    transform: 'scale(1.1)'
                },
                "& .titleContainer": {
                    flexDirection: 'row',
                    alignItems: 'flex-end'
                },
            },
            "& .newsArea": {
                backgroundColor: theme.palette.background.default,
                height: "100%",
                padding: "50px 0 5rem 0",
                "& .titleContainer": {
                    justifyContent: 'flex-start',
                },
                "& .bigScreenNewsSwiperContainer": {
                    position: "relative",
                    padding: 0,
                },
                "&:before": {
                    bottom: "-30%",
                    left: "-125%",
                },
            },
        },
    },
}));
