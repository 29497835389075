import React, { useState, useEffect } from "react";
import Input from "../../../components/Input";
import Modal from "../../../components/Modal";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import Table from "../../../components/Table";
import Status from "../../../components/Status";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ScholarshipService from "../../../services/ScholarshipService";
import FormService from "../../../services/FormService";
import useError from "../../../util/hooks/use-error";
import { noty } from '../../../util/notification';
const useStyles = makeStyles((theme) => ({
  Meetings: {
    color: theme.palette.text.primary,
  },
  functions: {
    width: "100%",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    "& .input": {
      borderRadius: "300px",
      width: "100%",
    },
    "& .buttons": {
      width: "30%",
      justifyContent: "flex-end",
      marginLeft: "20%",
      alignItems: "flex-end",
    },
    "& .button": {
      position: "relative",
      right: 0,
      border: `1px ${theme.palette.primary.main} solid`,
      color: theme.palette.primary.main,

      borderRadius: "30px",
      width: "100px",
      marginRight: "20px",
      marginBottom: "10px",
    },
    "& .icon:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    "& .form": {
      width: "70%",
    },
  },
  Modal: {
    "& .question, & .MuiTextField-root": {
      width: "100%",
    },
    "& .selection": {
      width: "90%",
      "& .MuiInputBase-root": {
        width: "100%",
      },
    },
    "& .modalBody": {
      minWidth: 500,
    },
    "& .title": {
      marginBottom: 20,
    },
    "& .submitBtn": {
      boxShadow: "none",
      color: "white",
      width: "100%",
      marginTop: 30,
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
    },
  },
}));

const Verify = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { errorHandler } = useError();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    getAndSetRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAndSetRow = () => {
    setRows([]);
    FormService.getFormList().then(res=>{
      console.log("lsit",res);
      let scholar = res.data.filter(row=>{
        return row.type==="scholarship";
      })
      let temp_row=[];
      scholar.forEach(row=>{
        temp_row.push({id:row.form_id,title:row.title});
      })
      ScholarshipService.getAdminScholarList()
      .then((res) => {
        console.log("list", res.data);
        let temp = [];
        setLoading(false);
        for (let key in res.data) {
          let scholarw = res.data[key];

          temp.push(createRow(scholarw, key,temp_row));
        }
        setRows(temp);
      })
      .catch((err) => errorHandler(err));


    })

  };



  const createRow = (scholar,id,list) => {
    const vstat = {
      success: (
        <Status
          icon={<CheckCircleIcon style={{ fontSize: "inherit" }} />}
          text="已通過"
          color={theme.palette.success.main}
        />
      ),
      denied: (
        <Status
          icon={<NotInterestedIcon style={{ fontSize: "inherit" }} />}
          text="未通過"
          color={theme.palette.error.main}
        />
      ),
      befDenied: (
        <Status
          icon={<NotInterestedIcon style={{ fontSize: "inherit" }} />}
          text="初審未過"
          color={theme.palette.error.main}
        />
      ),

      audit_bef: (
        <Button
          style={{
            border: `1px ${theme.palette.primary.main} solid`,
            borderRadius: "10px",
            color: theme.palette.primary.main,
          }}
          onClick={() => handleModalOpen("bef", "", scholar.scholarship_id)}
        >
          初審
        </Button>
      ),
      audit_aft: (
        <Button
          style={{
            border: `1px ${theme.palette.primary.main} solid`,
            borderRadius: "10px",
            color: theme.palette.primary.main,
          }}
          onClick={() => handleModalOpen("aft", "", scholar.scholarship_id)}
        >
          複審
        </Button>
      ),
      deniedBut: (
        <Button
          disabled
          style={{
            border: `1px ${theme.palette.text.disabled} solid`,
            borderRadius: "10px",
            color: theme.palette.text.disabled,
          }}
        >
          待初審
        </Button>
      ),
    };
    const time=scholar.timestamp;
    console.log("time", time);
    const applyBut = (
      <Button
        className="button"
        style={{
          border: `1px ${theme.palette.primary.main} solid`,
          color: theme.palette.primary.main,
          borderRadius: "10px",
        }}
      >
        <a
          style={{ color: theme.palette.primary.main }}
          href={`${scholar.application_file.file_location}`}
          download="123"
        >
          下載申請表單
        </a>
      </Button>
    );


    let award_id = scholar.form.form_id;
    let award_name=null;
    award_name = list.find(row=>{return row.id===award_id}).title;
    let before = "",
      after = "";
    if (scholar.status === 0) {
      before = "audit_bef";
      after = "deniedBut";
    } else if (scholar.status === 1) {
      before = "denied";
      after = "befDenied";
    } else if (scholar.status === 2) {
      before = "success";
      after = "audit_aft";
    } else if (scholar.status === 3) {
      before = "success";
      after = "denied";
    } else if (scholar.status === 4) {
      before = "success";
      after = "success";
    }
    console.log("test",award_name);


    if(award_name!=null){
    return {

        cells: {
          name: scholar.applicant_user.name,
          student_no: scholar.applicant_user.student_id,
          timestamp: time,
          award: award_name,
          apply: applyBut,
          before: vstat[before],
          after: vstat[after],
        },
        data: {
          id: scholar.scholarship_id,
          student_no: scholar.applicant_user.id,
          award: award_name,
          timestamp: time,
          name: scholar.applicant_user.name,
        },


    };
  }
  };



  const headCells = [
    {
      id: "name",
      label: "申請人",
      filter: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "輸入姓名...",
        },
        label: "申請人",
        value: "",
      },
    },
    {
      id: "student_no",
      label: "學號",
      filter: {
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "輸入學號...",
        },
        label: "學號",
        value: "",
      },
    },

    { id: "timestamp", label: "申請日期" },
    {
      id: "award",
      label: "獎學金名稱",
      filter: {
        elementType: "select",
        elementConfig: {
          options: [
            {
              value: "社會實踐輔導獎助學金",
              displayValue: "社會實踐輔導獎助學金",
            },
            {
              value: "中央大學服務學習績優獎學金申請表(個人)",
              displayValue: "中央大學服務學習績優獎學金申請表(個人)",
            },
            {
              value: "中央大學服務學習績優獎學金申請表(團體)",
              displayValue: "中央大學服務學習績優獎學金申請表(團體)",
            },
            {
              value: "公益服務獎學金(個人獎)",
              displayValue: "公益服務獎學金(個人獎)",
            },
            {
              value: "公益服務獎學金(團隊獎)",
              displayValue: "公益服務獎學金(團隊獎)",
            },
            {
              value: "國立中央大學國際服務學習獎學金",
              displayValue: "國立中央大學國際服務學習獎學金",
            },
          ],
        },
        label: "獎學金名稱",
        value: "",
      },
    },
    { id: "apply", label: "申請表單" },
    { id: "before", label: "初審" },
    { id: "after", label: "複審" },
  ];


  const [modalOpenState, setModalOpenState] = useState({
    bef: false,
    aft: false,
    content: "",
    type: "deny",
    editId: null,
  });
  const handleModalClose = () => {
    setModalOpenState({
      bef: false,
      aft: false,
      content: "",
      type: "deny",
      editId: null,
    });
  };
  const handleModalOpen = (step, content = "", id) => {
    console.log("opem model");
    if (step === "bef")
      setModalOpenState({
        ...modalOpenState,
        bef: true,
        content: content,
        type: "deny",
        editId: id,
      });
    if (step === "aft")
      setModalOpenState({
        ...modalOpenState,
        aft: true,
        content: content,
        type: "deny",
        editId: id,
      });
  };



  const handletypeChanged = async (id, value) => {
    setModalOpenState({ ...modalOpenState, type: value });
  };

  const handleContentChanged = async (id, value) => {
    setModalOpenState({ ...modalOpenState, content: value });
  };



  const handleBefSubmit = () => {
    let status;
    let  comment=modalOpenState.content;
    let id = modalOpenState.editId;
    let newRow = {};
    newRow.scholarship_id = id;

    setLoading(true);
    if (modalOpenState.type === "deny") {
      status = 1;
      newRow.status = status;
    } else if (modalOpenState.type === "pass") {
      status = 2;
      newRow.status = status;
    }
    let data = {
      scholarship_id: id,
      status: status,
      comment: comment,
    };
    ScholarshipService.postAdminScholarRev(id, data)
      .then((res) => {
        setLoading(false);
        noty("success", "審核成功!");
        getAndSetRow();
      })
      .catch((err) => errorHandler(err));
    handleModalClose();
  };
  const handleAftSubmit = () => {
    let status;
    const id = modalOpenState.editId;


    setLoading(true);
    if (modalOpenState.type === "deny") {
      status = 3;
    } else if (modalOpenState.type === "pass") {
      status = 4;
    }
    let data = {
      scholarship_id: id,
      status: status,
      comment: modalOpenState.content,
    };
    ScholarshipService.postAdminScholarRev(id, data)
      .then((res) => {
        setLoading(false);
        noty("success", "審核成功!");
        getAndSetRow();
      })
      .catch((err) => errorHandler(err));
    handleModalClose();
  };
  const form = {
    type: {
      id: "type",
      elementType: "select",
      value: modalOpenState.type,
      elementConfig: {
        options: [
          {
            value: "pass",
            displayValue: (
              <Status
                icon={<CheckCircleIcon style={{ fontSize: "inherit" }} />}
                text="通過"
                color={theme.palette.success.main}
              />
            ),
          },
          {
            value: "deny",
            displayValue: (
              <Status
                icon={<NotInterestedIcon style={{ fontSize: "inherit" }} />}
                text="未通過"
                color={theme.palette.error.main}
              />
            ),
          },
        ],
      },
      label: "審核結果",
    },
    content: {
      id: "contenter",
      elementType: "textarea",
      value: "",
      elementConfig: {
        type: "text",
        placeholder: "請簡短敘述內容",
      },
      label: "通過/未通過原因(意見)",
    },
  };

  return (
    <div className={classes.Meetings}>
      <h1>獎學金審核</h1>

      <Table
        tableTitle="名單管理"
        headCells={headCells}
        rows={rows}

        loading={loading}
        minWidth={1100}
        noCheckbox={true}
        rowOnClick={() => "notClickable"}
        orderBy="timestamp"
        order="desc"
      />
      <Modal
        className={classes.Modal}
        opened={modalOpenState.bef}
        handleClose={handleModalClose}
      >
        <h4 className="title">初審</h4>
        <Input
          className="inputStat"
          key={form.type.id}
          id={form.type.id}
          elementType={form.type.elementType}
          elementConfig={form.type.elementConfig}
          value={modalOpenState.type}
          onChange={handletypeChanged}
          label={form.type.label}
        />
        <Input
          className="comment"
          key={form.content.id}
          id={form.content.id}
          elementType={form.content.elementType}
          elementConfig={form.content.elementConfig}
          value={modalOpenState.content}
          onChange={handleContentChanged}
          label={form.content.label}
        />
        <Button
          className="submitBtn"
          onClick={handleBefSubmit}
          variant="contained"
          color="primary"
        >
          確認修改
        </Button>
      </Modal>
      <Modal
        className={classes.Modal}
        opened={modalOpenState.aft}
        handleClose={handleModalClose}
      >
        <h4 className="title">複審</h4>
        <Input
          className="inputStat"
          key={form.type.id}
          id={form.type.id}
          elementType={form.type.elementType}
          elementConfig={form.type.elementConfig}
          value={modalOpenState.type}
          onChange={handletypeChanged}
          label={form.type.label}
        />
        <Input
          className="comment"
          key={form.content.id}
          id={form.content.id}
          elementType={form.content.elementType}
          elementConfig={form.content.elementConfig}
          value={modalOpenState.content}
          onChange={handleContentChanged}
          label={form.content.label}
        />
        <Button
          className="submitBtn"
          onClick={handleAftSubmit}
          variant="contained"
          color="primary"
        >
          確認修改
        </Button>
      </Modal>

    </div>
  );
};

export default Verify;
