import axios from './instance/axios';

const FormService =  {
    getFormList: async () => {
        try{
            const response = await axios.get(`/form`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getForm: async (id) => {
        try{
            const response = await axios.get(`/form/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postAdminForm: async (data) => {
        try{
            const response = await axios.post("/admin/form", data)
            return response.data
        } catch (error) {
            throw error;
        }
    },
    deleteAdminForm: async (id) => {
        try{
            const response = await axios.delete(`/admin/form/${id}`)
            return response.data
        } catch (error) {
            throw error;
        }
    }
}

export default FormService;