import InterImg from '../assets/images/international.jpg';
import evtImg from '../assets/images/event.jpg';
import artImg from '../assets/images/art.jpg';
import knldgeImg from '../assets/images/knowledge.jpg';
import servImg from '../assets/images/service.jpg';
import Icon from '@material-ui/core/Icon';

export const getHrInfo = (hrName) => {
    switch(hrName) {
        case "大一週會":
        case "院週會":
        case "自我探索與生涯規劃":
        case "大一CPR":
        case "生活知能": return {
            description: "生活知能基本時數內含8小時大一週會、4小時院週會、5小時大一CPR、10小時自我探索與生涯規劃，以及8小時自主學習(其餘生活知能學習)，共35小時。若生活知能基本時數任一子項目已達畢業門檻，則該子項目多出時數將新增至高階時數。",
            color:"#ffbe0b",
            defaultImg: knldgeImg
        }
        case "人文藝術": return {
            description: "以提升藝能素養、陶冶心性、涵育氣質的活動；包含心靈美學音樂藝術教育與欣賞、人文藝術研討（習）講演講座、鄉土與歷史文物古蹟巡禮與學習、藝能技術之學習等。",
            color:"#3a86ff",
            defaultImg: artImg
        }
        case "國際視野": return {
            description: "指能提升國際移動能力與國際觀的活動；包含國際志工、國際移地學習、兩岸交流學習、校園國際化活動、國際學術交流活動、境外交換學生、境外短期學術參訪、校內外舉辦之全外文或國際學術研討會、擔任校內海外來訪國際學生接待、其他有關國際視野學習活動等",
            color:"#ff006e",
            defaultImg: InterImg
        }
        case "校外服務":
        case "愛校服務":
        case "服務學習": return {
            description: "服務學習基本時數內含課程30小時、校外服務10小時，共40小時。服務學習課程時數計算為1學期15小時。",
            color:"#8338ec",
            defaultImg: servImg
        }
        default: return {
            description: "還沒有時數相關介紹",
            color:"#F57C00",
            defaultImg: evtImg
        }
    }
}

export const getHrGroupInfo = hrGroupName => {
    switch(hrGroupName) {
        case "學生學習護照": return {
            icon: <Icon className={`fas fa-medal`} />
        }
        case "軟實力": return {
            icon: <Icon className={`fas fa-tools`} />
        }
        case "SDGs": return {
            icon: <Icon className={'fa fa-globe'} />
        }
        case "安心就學": return {
            icon: <Icon className={`fas fa-school`} />
        }
        default: return {
            icon: <Icon className={`fas fa-question`} />
        }
    }
}
