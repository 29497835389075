import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

function EnhancedTableHead(props) {
    const {
        classes,
        noCheckbox,
        onSelectAllClick,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        headCells,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    let order = props.order;

    // FIXME: change to normal
    if (order === "desc") {
        order = "asc";
    }
    else {
        order = "desc";
    }

    return (
        <TableHead>
            <TableRow>
                {noCheckbox ? null : (
                    <TableCell padding="checkbox">
                        <Tooltip title="全選">
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                            />
                        </Tooltip>
                    </TableCell>
                )}
                {headCells.map((headCell) => {
                    if (headCell.label && (headCell.notSortable || props.loading)) {
                        return (
                            <TableCell key={headCell.id}>
                                <div className="text">{headCell.label}</div>
                            </TableCell>
                        );
                    } else if (headCell.label) {
                        return (
                            <TableCell
                                key={headCell.id}
                                sortDirection={orderBy === headCell.id ? order : false}
                            >
                                {
                                    props.sortDisable ? headCell.label :
                                        <TableSortLabel
                                            active={orderBy === headCell.id}
                                            direction={orderBy === headCell.id ? order : "asc"}
                                            onClick={props.backendSort ? () => { props.backendSort(order, headCell.id); onRequestSort(null, headCell.id); } : createSortHandler(headCell.id)}
                                        >
                                            {headCell.label}
                                            {orderBy === headCell.id ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === "desc"
                                                        ? "sorted descending"
                                                        : "sorted ascending"}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                }

                            </TableCell>
                        );
                    }
                    return null;
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
