import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const MenuToggle = (props) => {
    const classes = useStyles(props);
    return (
        <div
            className={classes.MenuToggle + (props.active ? " active" : "")}
            onClick={props.click}
        >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
        </div>
    )
}

export default MenuToggle;

const useStyles = makeStyles(theme => ({
    MenuToggle: {
        height: props => props.height+"px",
        width: props => props.width+"px",
        display: "block",
        position: "relative",
        cursor: "pointer",
        "& .bar": {
            width: props => props.width+"px",
            height: props => props.lineHeight+"px",
            display: "block",
            borderRadius: "2px",
            backgroundColor: "#ff7326",
            transitionProperty: "transform, opacity, top, left",
            transitionDuration: ".2s",
            transitionTimingFunction: "inherit",
            position: "absolute",
            "&:nth-of-type(1)":{
                top: 0
            },
            "&:nth-child(2)": {
                top: props => `${(props.height - props.lineHeight)/2}px`
            },
            "&:nth-child(3)": {
                top: props => `${props.height - props.lineHeight}px`
            }
        },
        "&.active": {
            "& span:nth-child(1)": {
                transform: "rotate3d(0, 0, 1, 45deg)",
                top: props => `${props.height/2 - props.lineHeight/2}px`
            },
            "& span:nth-child(2)": {
                opacity: 0
            },
            "& span:nth-child(3)": {
                transform: "rotate3d(0, 0, 1, -45deg)",
                top: props => `${props.height/2 - props.lineHeight/2}px`
            }
        },
        [theme.breakpoints.up('xl')]: {
            display: 'none'
        }
    }
}));
