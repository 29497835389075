import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { ArrowForward, ArrowBack } from '@material-ui/icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/components/lazy/lazy.scss';

import Container from '../../../components/layouts/Container';
import Loader from '../../../components/Loader';

import withWidth from '@material-ui/core/withWidth';

const ActivitiesSwiper = (props) => {
    const classes = useStyles();
    const ref = useRef(null);
    const [slides, setSlides] = useState(null);

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };

    useEffect(() => {
        if (props.cards) {
            let updatedSlides = [];
            for (let [index, card] of props.cards.entries()) {
                updatedSlides.push(
                    <SwiperSlide
                        key={`slide-${index}`}
                        className="slide"
                    >
                        {card}
                    </SwiperSlide>
                );
            }
            setSlides(updatedSlides);
        }
    }, [props.cards]);

    return (
        <div className={classes.activitiesSwiper}>
            {
                slides ?
                    <Swiper
                        ref={ref}
                        slidesPerView='auto'
                        slidesPerGroup={1}
                        spaceBetween={20}
                        loop
                        freeMode
                        // centeredSlides
                        // lazy
                        // watchSlidesVisibility
                        preloadImages={false}
                    >
                        {slides}
                    </Swiper>
                    :
                    <div className='loadingSwiper'>
                        <Loader />
                    </div>
            }
            <Container className="controlPanelContainer">
                <div className='controlPanel' >
                    <ArrowBack
                        className='arrow'
                        onClick={goPrev}
                        color="primary"
                    />
                    <ArrowForward
                        className='arrow'
                        onClick={goNext}
                        color="primary"
                    />
                </div>
            </Container>
        </div>
    );
};

export default withWidth()(ActivitiesSwiper);

// -------------------------style--------------------------
const useStyles = makeStyles(theme => ({
    activitiesSwiper: {
        position: 'relative',
        '& .controlPanel': {
            display: "none",
        },
        '& .slide': {
            boxSizing: "border-box",
            width: "250px",
            margin: '1rem 0'
        },
        '& .loadingSwiper': {
            width: '100%',
            height: '400px',
            backgroundColor: `${theme.palette.background.paper}AA`,
            display: 'flex',
            alignItems: 'center',
            borderRadius: 15,
        }
    },
    [theme.breakpoints.up("lg")]: {
        activitiesSwiper: {
            '& .controlPanelContainer': {
                '& .controlPanel': {
                    paddingTop: 15,
                    marginLeft: 'auto',
                    width: 100,
                    display: "flex",
                    justifyContent: "space-around",
                    '& .arrow': {
                        fontSize: 40,
                        "&:hover": {
                            cursor: "pointer"
                        },
                        "&:active": {
                            filter: "brightness(150%)"
                        }
                    }
                }
            }
        }
    }
}));
