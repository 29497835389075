import axios from './instance/axios';

const ActivityService = {
    getActivity: async (activityId) => {
        try {
            const response = await axios.get(`/activity/${activityId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getLatestActivityList: async () => {
        try {
            const response = await axios.get("/show_latest_activity");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAdminActivityListInSpecificStatus: async (status) => {
        try {
            const response = await axios.get(`/admin/activity/list_activity_in_specific_status/${status}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postActivity: async (permission, data) => {
        try {
            const response = await axios.post(`/${permission}/activity`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    deleteActivity: async (id) => {
        try {
            const response = await axios.delete(`/newActivities.json/${id}.json`)
            return response.data
        } catch (error) {
            throw error
        }
    },
    putActivity: async (permission, id, data) => {
        try {
            const response = await axios.put(`/${permission}/activity/${id}`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    postCoverImg: async (id, data, permission = 'organization') => {
        try {
            const response = await axios.post(`/${permission}/activity/update_cover_file/${id}`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    postSignatureFile: async (id, data) => {
        try {
            const response = await axios.post(`/organization/activity/update_application_file/${id}`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    getOrgSignOutQrCode: async (id) => {
        try {
            const response = await axios.get(`/organization/activity/show_signout_qrcode/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getAdminSignOutQrCode: async (id) => {
        try {
            const response = await axios.get(`/admin/activity/show_signout_qrcode/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getOrgApplyingActivityList: async (groupId) => {
        try {
            const response = await axios.get(`/organization/activity/show_activities_in_application/${groupId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getOrgProcessingActivityList: async (groupId) => {
        try {
            const response = await axios.get(`/organization/activity/show_activities_in_action/${groupId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getOrgFinishedActivityList: async (groupId) => {
        try {
            const response = await axios.get(`/organization/activity/show_past_activities/${groupId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postAdminApproveActivityApplication: async (data, activityId) => {
        try {
            const response = await axios.post(`/admin/activity/approve_activity_application/${activityId}`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    postAdminApproveActivityHourCert: async (data, activityId) => {
        try {
            const response = await axios.post(`/admin/activity/approve_activity_hour_certifications/${activityId}`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    getStudentEnrolledActivityList: async () => {
        try {
            const response = await axios.get("/student/activity/list_enrolled_activity");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStudentAttendedActivityList: async () => {
        try {
            const response = await axios.get("/student/activity/list_attended_activity");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getStudentFeedbackActivityList: async () => {
        try {
            const response = await axios.get("/student/activity/list_attended_activity");
            const data = { data: response.data.data.filter(activity => activity.questionnaire || activity.report) };
            return data;
        } catch (error) {
            throw error;
        }
    },
    submitActivityApply: async (id) => {
        try {
            const response = await axios.get(`/organization/activity/submit_activity_application/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    submitAdminActivityApply: async (id) => {
        try {
            const response = await axios.get(`/admin/activity/submit_and_approve_activity_application/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postUpdateSignoutQrcodeAvailableTime: async (data) => {
        try {
            const response = await axios.post(`/organization/activity/update_signout_qrcode_available`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postActivitySearch: async (data, page, permission = 'admin') => {
        try {
            const response = await axios.post(`/${permission}/activity/search?page=${page}`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    getIndividualActivity: async () => {
        try {
            const response = await axios.get("/student/individualActivity");
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postIndividualActivity: async (data) => {
        try {
            const response = await axios.post("/student/individualActivity", data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    putIndividualActivity: async (id, data) => {
        try {
            const response = await axios.put(`/student/individualActivity/${id}`, data)
            return response.data
        } catch (error) {
            throw error
        }
    },
    submitIndividualActivityApply: async (id) => {
        try {
            const response = await axios.get(`/student/individualActivity/submit_activity_application/${id}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    postActivityFile: async (id, data) => {
        try {
            const response = await axios.post(`/user/activity/${id}/file`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    deleteActivityFile: async (activityId, fileId) => {
        try {
            const response = await axios.delete(`/user/activity/${activityId}/file/${fileId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default ActivityService;
