import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import produce from "immer";
import useScrollPosition from "@react-hook/window-scroll";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Input from "../../Input";

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        numSelected,
        headCells,
        titleBarControls,
        setPage,
        setFilterRows,
        rows,
        filterRows
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [filterForm, setFilterForm] = useState("");
    const scrollY = useScrollPosition(60 /*fps*/);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const form = {};
        for (const cell of headCells) {
            if (cell.filter) {
                form[cell.id] = cell.filter;
            }
        }
        setFilterForm(form);
        let bodyRect = document.body.getBoundingClientRect();
        let toolbarRect = document.getElementById("toolbar").getBoundingClientRect();
        let offset = toolbarRect.top - bodyRect.top;
        setOffset(offset);
    }, [headCells]);

    useEffect(() => {
        let stickyToolbar = document.getElementById("sticky-toolbar");
        if (scrollY >= offset) {
            stickyToolbar.classList.add("show");
        } else {
            stickyToolbar.classList.remove("show");
        }
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClearFilter = () => {
        if (props.handleClearFilter) {
            props.handleClearFilter();
        }
        setFilterForm((prevState) => {
            const updatedForm = {
                ...prevState,
            };
            for (let id in updatedForm) {
                updatedForm[id] = { ...prevState[id] };
                if (updatedForm[id].elementType === "input") {
                    updatedForm[id].value = "";
                }
                else if (updatedForm[id].elementType === "select") {
                    updatedForm[id].value = "all";
                }
            }
            return updatedForm;
        });
    };

    useEffect(() => {
        if (anchorEl && !props.backendSupported) {
            setTimeout(
                (lastFilterForm) => {
                    let newRows = rows;
                    for (let id in lastFilterForm) {
                        if (lastFilterForm[id].elementType === "input") {
                            let lastValue = lastFilterForm[id].value;
                            let currentTarget = document.getElementById(`input-${id}`);
                            let currentValue = currentTarget ? currentTarget.value : null;
                            if (currentValue === lastValue && lastValue.length !== 0) {
                                newRows = newRows.filter((row) => {
                                    if (Array.isArray(row.data[id])) {
                                        for (let element of row.data[id]) {
                                            if (element.includes(currentValue)) {
                                                return true;
                                            }
                                        }
                                    }
                                    else {
                                        return row.data[id].includes(currentValue);
                                    }
                                    return false;
                                });
                            }
                        }
                        else if (lastFilterForm[id].elementType === "select") {
                            let value = lastFilterForm[id].value;
                            if (value !== "all") {
                                newRows = newRows.filter((row) => {
                                    if (Array.isArray(row.data[id])) {
                                        for (let element of row.data[id]) {
                                            if (element === value) {
                                                return element === value;
                                            }
                                        }
                                    }
                                    else {
                                        return row.data[id] === value;
                                    }
                                    return false;
                                });
                            }
                        }
                    }
                    setFilterRows(newRows);
                    setPage(0);
                },
                1000,
                filterForm
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterForm, rows, setFilterRows]);

    const handleInputChanged = (inputId, value) => {
        let updatedForm = produce(filterForm, (draft) => {
            draft[inputId].value = value;
        });
        setFilterForm(updatedForm);
    };

    const handleSearch = () => {
        if (anchorEl && props.backendSupported) {
            let newRows = rows;
            for (let id in filterForm) {
                if (filterForm[id].elementType === "input") {
                    let value = filterForm[id].value;
                    if (filterForm[id].backendFilter) {
                        filterForm[id].backendFilter(value);
                    }
                } else if (filterForm[id].elementType === "select") {
                    let value = filterForm[id].value;
                    if (filterForm[id].backendFilter) {
                        filterForm[id].backendFilter(value);
                    }
                }
            }
            setFilterRows(newRows);
            setPage(0);
        }
    }

    let title = null;
    if (numSelected > 0) {
        title = `您選擇了 ${numSelected} 個項目`;
    }
    else {
        title = props.tableTitle ? props.tableTitle : "";
    }

    let buttons = null;
    if (numSelected > 0) {
        buttons = props.buttonData.map((b, index) => (
            <Tooltip key={index} title={b.title}>
                <IconButton
                    disabled={props.loading}
                    onClick={() => {
                        b.onClick(props.selected);
                        props.onEmptySelect();
                    }}
                >
                    {b.icon}
                </IconButton>
            </Tooltip>
        ));
    } else {
        buttons = (
            <>
                {titleBarControls
                    ? titleBarControls.map((control, index) => (
                        <Tooltip key={index} title={control.title}>
                            {control.component ? (
                                <IconButton
                                    disabled={props.loading || control.disabled}
                                    containerElement={control.component}
                                >
                                    {control.icon}
                                </IconButton>
                            ) : (
                                <IconButton
                                    disabled={props.loading || control.disabled}
                                    onClick={(event) => {
                                        if (control.usingFilterRows) {
                                            control.onClick(filterRows);
                                        }
                                        else {
                                            control.onClick(event);
                                        }
                                        props.onEmptySelect();
                                    }}
                                    containerElement={control.component}
                                >
                                    {control.icon}
                                </IconButton>
                            )}
                        </Tooltip>
                    ))
                    : null
                }
                {
                    props.noFilter ? null :
                        <Tooltip title="過濾" disableFocusListener={props.loading}>
                            <IconButton
                                className="filterlistIcon"
                                disabled={props.loading}
                                onClick={handleClick}
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                }

                <Menu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    elevation={3}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <div className="filterArea">
                        {Object.entries(filterForm).map(([id, config]) => {
                            return (
                                <Input
                                    key={id}
                                    domId={`input-${id}`}
                                    id={id}
                                    className="filterItem"
                                    elementType={config.elementType}
                                    elementConfig={config.elementConfig}
                                    helperText={config.helperText}
                                    value={config.value}
                                    label={config.label}
                                    onChange={handleInputChanged}
                                />
                            );
                        })}

                        <div className="btnArea">
                            {
                                props.backendSupported ?
                                    <Button
                                        className="searchBtn"
                                        color="primary"
                                        variant="contained"
                                        onClick={handleSearch}
                                    >
                                        搜尋
                                    </Button> : null
                            }
                            <Button
                                className="clearBtn"
                                color="primary"
                                variant={`${props.backendSupported ? "outlined" : "contained"}`}
                                onClick={handleClearFilter}
                            >
                                清除
                            </Button>
                        </div>

                    </div>
                </Menu>
            </>
        );
    }

    return (
        <>
            <Toolbar
                className={clsx(classes.Toolbar, {
                    [classes.themeHighlight]: numSelected > 0,
                    [classes.themeBasic]: numSelected <= 0,
                })}
                id="toolbar"
            >
                <h5 className={`${classes.title} title`}>{title}</h5>
                {buttons}
            </Toolbar>

            <Toolbar
                className={clsx(classes.StickyToolbar, {
                    [classes.themeHighlight]: numSelected > 0,
                    [classes.themeBasic]: numSelected <= 0,
                })}
                id="sticky-toolbar"
            >
                <h5 className={classes.title}>
                    {title === props.tableTitle ? null : title}
                </h5>
                {numSelected > 0 ? buttons : null}
            </Toolbar>
        </>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;

const useToolbarStyles = makeStyles((theme) => ({
    "@global": {
        "#filter-menu": {
            width: 500,
            "& .filterArea": {
                width: 250,
                display: "flex",
                flexDirection: 'column',
                "& .filterItem": {
                    marginRight: "0.5rem",
                    marginTop: 10
                },
                "&:focus": {
                    outline: "none",
                },
            },
            "& .MuiList-padding": {
                padding: 15,
            },
            "& .MuiListItem-root": {
                "&:focus": {
                    outline: "none",
                },
            },
            "& .btnArea": {
                display: 'flex',
                "& .clearBtn, & .searchBtn": {
                    flex: 1,
                    margin: 5,
                    boxShadow: "none",
                    color: "white",
                    maxHeight: 50,
                    marginTop: 15
                },
                "& .clearBtn": {
                    color: theme.palette.text.primary
                }
            }

        },
    },
    Toolbar: {
        flexWrap: 'wrap',
        padding: "0px 15px",
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        "& .MuiToolbar-gutters": {
            paddingLeft: 0,
        },
        "& .MuiIconButton-root": {
            color: theme.palette.text.primary,
            "&.Mui-disabled": {
                color: theme.palette.text.disabled
            }
        },
    },
    StickyToolbar: {
        display: "none",
        "& .MuiCheckbox-root": {
            color: theme.palette.text.secondary,
        },
        "&.show": {
            display: "flex",
            padding: "0px 10vw",
            position: "fixed",
            flexWrap: 'wrap',
            justifyContent: "flex-end",
            top: 80,
            left: 0,
            width: "100%",
            zIndex: 10,
            boxSizing: "border-box",
        },
    },
    themeHighlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.main,
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    themeBasic: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.paper,
    },
    title: {
        flex: "1 1 100%",
        margin: 0,
        marginTop: 10
    },
    [theme.breakpoints.up('sm')]: {
        Toolbar: {
            flexWrap: 'initial'
        },
        StickyToolbar: {
            flexWrap: 'initial'
        },
        title: {
            flex: 'initial',
            marginRight: 'auto',
            marginTop: 0,
        },
    }
}));
