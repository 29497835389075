import axios from "./instance/axios";

const ScholarshipService = {
  getAdminScholarList: async () => {
    try {
      const response = await axios.get("/admin/scholarship");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getStuScholarList: async () => {
    try {
      const response = await axios.get("/student/scholarship");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postAdminScholarRev: async (id, data) => {
    try {
      const response = await axios.put(`/admin/scholarship/${id}`, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postScholarApply: async (data) => {
    try {
      const response = await axios.post("/student/scholarship", data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteStuScholar: async (id) => {
    try {
      const response = await axios.delete(`/student/scholarship/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getStuScholar: async (id) => {
    try {
      const response = await axios.get(`/student/scholarship/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAdminScholar: async (id) => {
    try {
      const response = await axios.get(`/admin/scholarship/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ScholarshipService;
