import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import NavigationItems from './NavigationItems';

const MainMenu = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.MainMenu + (props.active ? " active" : "")}>
            <nav>
                <NavigationItems/>
            </nav>
        </div>
    )
}

export default MainMenu;

const useStyles = makeStyles(theme => ({
    MainMenu: {
        backgroundColor: theme.palette.background.paper,
        position: "fixed",
        zIndex: "100",
        height: "100%",
        overflow: "auto",
        top: '0',
        opacity: "0",
        visibility: "hidden",
        transition: ".2s opacity",
        width: '100%',
        '& .navItems' :{
            fontSize: '25px',
            padding: "80px 0 40px 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            height: "450px"
        },
        '&.active' :{
            opacity: "1",
            visibility: "visible",
            transitionDuration: "0.2s",
        }
    }
}));
